import { LogoPng } from "../../Assets";
import Card from "../../Components/Card";
import Head from "../../Components/Head";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import SubTitle from "../../Components/SubTitle";
import Title from "../../Components/Title";
import React, { useEffect } from "react";
import { useClient } from "../../Utils/Context/ClientContext";
import Loader from "../../Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select2 from "../../Components/Select2";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import { useProgram } from "../../Utils/Context/ProgramContext";
import { useClientProgram } from "../../Utils/Context/ClientProgramContext";

export default function ProgramClient() {
  const { client, isLoading, fetchById } = useClient();
  const { fetchAll: fetchAllProgram, programs } = useProgram();
  const { store, isSuccess } = useClientProgram();

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [program_id, setProgram_id] = useState(null);

  useEffect(() => {
    isSuccess && navigate(-1);
    function getData() {
      fetchById(id);
    }
    getData();
  }, [isSuccess]);

  useEffect(() => {
    fetchAllProgram({ keyword: "" });
  }, []);

  const storeExecute = async (e) => {
    e.preventDefault();
    await store({
      client_id: id,
      program_id: program_id,
      progress: "{}",
    });
  };

  return isLoading ? (
    <div className="flex justify-center items-center w-screen h-screen">
      <Loader />
    </div>
  ) : (
    <div className="py-10 h-min-screen dark:bg-gray-700 bg-slate-100 px-5 md:p-32 bg-[url('/src/Assets/bg-landmark-2.svg')] bg-no-repeat bg-contain">
      <Head title="Counselation" />
      <Toaster />
      <Title>{client.user.name}</Title>
      {client.school?.name !== undefined && (
        <SubTitle>
          {client.school?.name} (
          {moment(client.graduated_at).format("MMM YYYY")})
        </SubTitle>
      )}
      <form onSubmit={storeExecute}>
        <Card>
          <div className="grid grid-cols-1 gap-3 w-full">
            <div>
              <InputLabel value={t("Program")} />

              <Select2
                isSearchable
                options={programs?.data}
                onSelected={(e) => setProgram_id(e.id)}
                onTextChanged={(keyword) =>
                  fetchAllProgram({ keyword: keyword })
                }
              />
            </div>
          </div>
        </Card>
        <div className="flex justify-center">
          <PrimaryButton disabled={isLoading} type={`submit`}>
            {isLoading ? <Loader /> : "Submit"}
          </PrimaryButton>
        </div>
      </form>
      <div className="border-t-slate-500 border-t my-10"></div>
      <div className="flex justify-center">
        <img alt="" src={LogoPng} className="w-full md:w-1/4" />
      </div>
    </div>
  );
}
