import { Footer } from "flowbite-react";
import Toggle from "../Components/Toggle";
import { useEffect, useState } from "react";
import { APP_NAME } from "../Utils/Constant";
import { useTranslation } from "react-i18next";

export default function DefaultFooter() {
  const [enabled, setEnabled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let toggle =
      localStorage.getItem("locale") === "id" ||
      localStorage.getItem("locale") === null
        ? false
        : true;
    setEnabled(toggle);
  }, []);

  const changeLng = (e) => {
    localStorage.setItem("locale", e ? "en" : "id");
    setEnabled(e);
    window.location.reload();
  };
  return (
    <div className="p-5 dark:bg-gray-900">
      <Footer container className="rounded-full shadow-lg border">
        <Footer.Copyright
          className="flex justify-center"
          by={APP_NAME}
          href="https://konsultanvisa.com"
          year={new Date().getFullYear()}
        />
        <Footer.LinkGroup>
          <div className="flex gap-3 w-full justify-center">
            <p className="flex items-center">{t("Bahasa")}</p>
            <Toggle enabled={enabled} onChange={(e) => changeLng(e)} />
            <p className="flex items-center">{t("English")}</p>
          </div>
        </Footer.LinkGroup>
      </Footer>
    </div>
  );
}
