import React, { useState } from "react";
import TextInput from "./TextInput";
import PrimaryButton from "./PrimaryButton";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Search = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [keyWord, setKeyWord] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      keyword: keyWord,
    });
  };

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 md:gap-3"
      onSubmit={handleSubmit}
    >
      <TextInput
        type="search"
        className="md:my-4 text-center md:text-left"
        placeholder={t("Looking for...")}
        value={keyWord}
        onChange={(e) => setKeyWord(e.target.value)}
      />
      <PrimaryButton type="submit" className="my-5 w-full md:w-fit">
        <p className="w-full text-center">
          <FontAwesomeIcon className="mr-3" icon={faSearch} />
          {t("Search")}
        </p>
      </PrimaryButton>
    </form>
  );
};

export default Search;
