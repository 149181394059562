import axios from "axios";
const BASE_URL = "https://api.konsultanvisaid.com/api/";

const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    const locale = sessionStorage.getItem("locale");
    if (config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";
      config.headers["Accept-Language"] = locale;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const instanceRequest = (method, url, config, data) => {
  const newConfig = {
    ...config,
  };
  if (method === "get") {
    return instance.get(url, newConfig);
  } else if (method === "post") {
    return instance.post(url, data, newConfig);
  } else if (method === "put") {
    return instance.put(url, data, newConfig);
  } else {
    return instance.delete(url, newConfig);
  }
};
