import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import Select2 from "../../Components/Select2";
import { usePayable } from "../../Utils/Context/PayableContext";
import { useProgram } from "../../Utils/Context/ProgramContext";
import { useLocation, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const payable_id = queryParams.get("id");

  const { fetchAll, programs } = useProgram();
  const { store, isSuccess, fetchById, isSuccessUpdate, payable, update } =
    usePayable();

  const [program_id, setProgramId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [default_rate, setDefault_rate] = useState("");
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    fetchAll({ keyword: "" });
  }, []);

  useEffect(() => {
    async function getData() {
      await fetchById(payable_id);
    }
    if (payable_id) {
      getData();
    }
  }, [payable_id]);

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Payable added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);

  useEffect(() => {
    if (payable) {
      setName(payable.name ?? "");
      setPrice(payable.price ?? "");
      setProgramId(payable.program_id ?? undefined);
      setCurrency(payable.currency ?? "");
      setDefault_rate(payable.default_rate ?? "");
    }
  }, [payable]);

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Payable has been updated"));
    }
    isSuccessUpdate && successHandle();
  }, [isSuccessUpdate]);

  const storeExe = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("currency", currency);
    formData.append("default_rate", default_rate);
    formData.append("price", price);
    if (program_id !== undefined) {
      formData.append("program_id", program_id);
    }

    if (payable) {
      await update(payable_id, formData);
    } else {
      await store(formData);
    }
  };
  return (
    <Authentication header={<Header>{t("Add new payable")}</Header>}>
      <Head title={t("Add new payable")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <form
            className="w-full"
            onSubmit={storeExe}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div>
                <InputLabel>{t("Payable Name")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <InputLabel>{t("Price")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={price}
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div>
                <InputLabel>{t("Program")}</InputLabel>
                {payable && `${t("choosed")}: ${payable?.program?.name}`}
                <Select2
                  isSearchable
                  options={programs?.data}
                  onSelected={(e) => setProgramId(e.id)}
                  onTextChanged={(keyword) => fetchAll({ keyword: keyword })}
                />
              </div>
              <div>
                <InputLabel>{t("Currency")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={currency}
                  type="text"
                  onChange={(e) => setCurrency(e.target.value)}
                />
              </div>
              <div>
                <InputLabel>{t("Rate in IDR")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={default_rate}
                  type="number"
                  onChange={(e) => setDefault_rate(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <PrimaryButton className="my-5 w-full md:w-fit">
                <p className="w-full text-center">Submit</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </Authentication>
  );
}
