import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useConsultation = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [consultations, setConsultations] = useState([]);

  const handleError = (e) => {
    setIsError(true);
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = () => {
    setIsLoading(false);
  };
  const handleInitial = () => {
    setIsLoading(true);
    setIsSuccess(false);
  };

  const fetchAll = async () => {
    handleInitial();
    await instanceRequest("get", "consultation", undefined, undefined)
      .then((r) => {
        setIsSuccess(true);
        setConsultations(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const fetchAllByUserId = async (user_id) => {
    handleInitial();
    const config = {
      params: {
        "filter[user_id]": user_id,
        include: ["counselor"],
      },
    };
    await instanceRequest("get", "consultation", config, undefined)
      .then((r) => {
        setIsSuccess(true);
        setConsultations(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const store = async (data) => {
    handleInitial();
    await instanceRequest("post", "consultation", undefined, data)
      .then((r) => {
        setIsSuccess(true);
        toast.success(t("Action Successful"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  return {
    isLoading,
    isError,
    isSuccess,
    consultations,
    fetchAll,
    fetchAllByUserId,
    store,
  };
};
