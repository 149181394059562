import { LogoPng } from "../../Assets";
import Card from "../../Components/Card";
import Head from "../../Components/Head";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import SubTitle from "../../Components/SubTitle";
import Title from "../../Components/Title";
import React, { useEffect } from "react";
import { useClient } from "../../Utils/Context/ClientContext";
import Loader from "../../Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useConsultation } from "../../Utils/Context/ConsultationContext";
import { Toaster } from "react-hot-toast";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import { useTemplate } from "../../Utils/Context/TemplateContext";

export default function ConsultationClient() {
  const { client, isLoading, fetchById } = useClient();
  const {
    store,
    isLoading: loadingConsultation,
    isSuccess,
  } = useConsultation();
  const { id } = useParams();
  const { t } = useTranslation();

  const [note, setNote] = useState("");
  const [userData, setUserData] = useState({});
  // const [first, setfirst] = useState(second)

  const navigate = useNavigate();

  const { fetchAll: fetchTemplateAll, templates } = useTemplate();

  useEffect(() => {
    fetchTemplateAll();
  }, []);

  const handleTemplate = (template_id) => {
    templates.data.find((template) => {
      if (template.id === template_id) {
        setNote(template.content);
      }
    });
  };

  useEffect(() => {
    isSuccess && navigate(-1);
    function getData() {
      fetchById(id);
      let user = JSON.parse(sessionStorage.getItem("user"));
      setUserData(user);
    }
    getData();
  }, [isSuccess]);

  const attemptConsultation = async (e) => {
    e.preventDefault();
    await store({
      note: note,
      user_id: client?.user?.id,
      counselor_id: userData?.id,
    });
  };

  return isLoading ? (
    <div className="flex justify-center items-center w-screen h-screen">
      <Loader />
    </div>
  ) : (
    <div className="py-10 h-min-screen dark:bg-gray-700 bg-slate-100 px-5 md:p-32 bg-[url('/src/Assets/bg-landmark-2.svg')] bg-no-repeat bg-contain">
      <Head title="Counselation" />
      <Toaster />
      <Title>{client.user.name}</Title>
      <SubTitle>
        {client?.school && (
          <>
            {client?.school?.name} (
            {moment(client?.graduated_at).format("MMM YYYY")})
          </>
        )}
      </SubTitle>
      <form onSubmit={attemptConsultation}>
        <Card>
          <div className="grid grid-cols-1 gap-3 w-full">
            <div>
              <div className="flex gap-3">
                <InputLabel htmlFor="note" value={t("Counsultation")} />
                {templates &&
                  templates.data.map((template, index) => {
                    return (
                      <PrimaryButton
                        onClick={() => handleTemplate(template.id)}
                        type="button"
                        key={index}
                      >
                        {template.name}
                      </PrimaryButton>
                    );
                  })}
              </div>

              <MDEditor
                value={note}
                onChange={setNote}
                height={700}
                className="rounded-3xl p-5 mt-5"
              />
            </div>
          </div>
        </Card>
        <div className="flex justify-center">
          <PrimaryButton disabled={loadingConsultation} type={`submit`}>
            {loadingConsultation ? <Loader /> : "Submit"}
          </PrimaryButton>
        </div>
      </form>
      <div className="border-t-slate-500 border-t my-10"></div>
      <div className="flex justify-center">
        <img alt="" src={LogoPng} className="w-full md:w-1/4" />
      </div>
    </div>
  );
}
