import moment from "moment";
import Card from "./Card";
import TimelineBody from "./TimelineBody";
import TimelineItem from "./TimelineItem";
import React, { useEffect } from "react";
import Title from "./Title";
import { useTranslation } from "react-i18next";
import MDEditor from "@uiw/react-md-editor";
import { usePlacementTest } from "../Utils/Context/PlacementTextContex";
import { getLabel } from "../Utils/Enums/Placement";
import { Tooltip } from "flowbite-react";
import PrimaryButton from "./PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useUser } from "../Utils/Context/UserContext";

export default function PlacementTestPage({ user_id }) {
  const { fetchByUserId, placementTest } = usePlacementTest();
  const { fetchById: getUser, user } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    user === null && getUser(user_id);
    user !== null && fetchByUserId(user_id);
  }, [user]);

  return (
    <Card>
      <TimelineBody>
        {placementTest === null ? (
          <Title>{t("Please wait...")}</Title>
        ) : placementTest.data.length < 1 ? (
          <Title>{t("No placement test found")}</Title>
        ) : (
          placementTest.data.map((item, index) => (
            <TimelineItem
              key={index}
              title={
                <div className="flex gap-4">
                  {t("Placement Test - ") + (index + 1)}
                  {user !== null && (
                    <Tooltip content={`Edit`}>
                      <Link
                        to={`/client/placement/${user?.client?.id}?placement_id=${item.id}`}
                      >
                        <PrimaryButton>
                          <FontAwesomeIcon icon={faPencil} />
                        </PrimaryButton>
                      </Link>
                    </Tooltip>
                  )}
                </div>
              }
              content={
                <>
                  <p>
                    {item.checked_by?.name &&
                      t("Checked by") + ": " + item.checked_by?.name}
                  </p>
                  <p>
                    {item.handled_by?.name &&
                      t("Handled by") + ": " + item.handled_by?.name}
                  </p>
                </>
              }
              date={moment(item.created_at).format("LLL")}
              body={
                <>
                  <p>
                    {t("Score")}: {item.score}
                  </p>
                  {item.essay_score && (
                    <p>
                      {t("Essay Score")}: {item.essay_score}
                    </p>
                  )}
                  <p>
                    {t("Placement")}: {getLabel(item.placement)}
                  </p>
                  {item.note && (
                    <>
                      <span>{t("Note")}:</span>
                      <MDEditor.Markdown
                        className="rounded-3xl p-5"
                        source={item.note}
                      />
                    </>
                  )}
                </>
              }
            />
          ))
        )}
      </TimelineBody>
    </Card>
  );
}
