import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useClientProgram = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
  });
  const [clientPrograms, setClientPrograms] = useState(null);
  const [clientProgram, setClientProgram] = useState(null);

  const handleApiError = (e) => {
    setStatus({ isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };

  const handleInitial = () =>
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
    });

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async ({ page = 1, keyword = "", limit = 10 }) => {
    const config = {
      params: {
        page: page,
        limit: limit,
        // include: ["user.placementTest", "school"],
        "filter[name]": keyword,
        sort: "name",
      },
    };
    setClientPrograms(
      await fetchData("get", "clientprogram", config, undefined, "isSuccessGet")
    );
  };

  const fetchById = async (id) => {
    const config = {
      params: {
        // include: ["user.placementTest", "school"],
      },
    };
    setClientProgram(
      await fetchData(
        "get",
        `clientprogram/${id}`,
        config,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const fetchByClientId = async (id) => {
    const config = {
      params: {
        "filter[client_id]": id,
        include: ["client", "program"],
      },
    };
    setClientPrograms(
      await fetchData("get", `clientprogram`, config, undefined, "isSuccessGet")
    );
  };

  const store = async (data) => {
    setClientProgram(
      await fetchData("post", `clientprogram`, undefined, data, "isSuccess")
    );
  };

  const remove = async (id) => {
    await fetchData(
      "delete",
      `clientprogram/${id}`,
      undefined,
      undefined,
      "isSuccessDestroy"
    );
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "put",
      `clientprogram/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  return {
    ...status,
    clientPrograms,
    clientProgram,
    store,
    remove,
    update,
    fetchAll,
    fetchById,
    fetchByClientId,
  };
};
