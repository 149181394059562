import {
  faCubes,
  faDashboard,
  faFileInvoice,
  faFont,
  faGears,
  faGrip,
  faLink,
  faMoneyBill,
  faMoneyCheck,
  faSchool,
  faUserGear,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export const APP_NAME = "Konsultan Visa";
export const PUBLIC_URL_SUPABASE =
  "https://pqxdbiaibeqfgxoxlkpg.supabase.co/storage/v1/object/public/assets%20KV%20management%20system/";

export const SIDEBAR_MENU = [
  {
    title: "Home",
    icon: faDashboard,
    route: "/",
    allowedRoles: ["super-admin", "admin", "counselor", "client"],
  },
  {
    title: "Our programs",
    icon: faGrip,
    route: "/application",
    allowedRoles: ["client"],
  },
  {
    title: "Client",
    icon: faUsers,
    route: "/client",
    allowedRoles: ["super-admin", "admin", "counselor"],
  },
  {
    title: "Shorten Link",
    icon: faLink,
    route: "/shortenlink",
    allowedRoles: ["super-admin", "admin", "counselor"],
  },
  {
    title: "Finance",
    icon: faMoneyBill,
    allowedRoles: ["super-admin", "admin", "counselor"],
    children: [
      {
        title: "Invoice",
        icon: faFileInvoice,
        route: "/invoice",
        allowedRoles: ["super-admin", "admin", "counselor"],
      },
      {
        title: "Payable",
        icon: faMoneyCheck,
        route: "/payable",
        allowedRoles: ["super-admin"],
      },
    ],
  },
  {
    title: "Admin",
    icon: faUserGear,
    route: "/user",
    allowedRoles: ["super-admin"],
  },
  {
    title: "Program",
    icon: faCubes,
    route: "/program",
    allowedRoles: ["super-admin"],
  },
  {
    title: "School",
    icon: faSchool,
    route: "/school",
    allowedRoles: ["super-admin"],
  },
  {
    title: "Settings",
    icon: faGears,
    allowedRoles: ["super-admin"],
    children: [
      {
        title: "Consultation Template",
        icon: faFont,
        route: "/template",
        allowedRoles: ["super-admin"],
      },
    ],
  },
];
