import React from "react";

export default function Card({ children, className }) {
  return (
    <div
      className={`rounded-3xl m-5 shadow-md bg-white dark:bg-gray-800 dark:text-white flex gap-3 py-10 px-5 md:px-10 ${className}`}
    >
      {children}
    </div>
  );
}
