const VisaType = {
  OtherVisa: -1,
  VisitorVisa: 0,
  StudentVisa: 1,
  InternshipVisa: 2,
};

const getVisaTypeLabel = (visaTypeValue) => {
  switch (visaTypeValue) {
    case VisaType.VisitorVisa:
      return "VisitorVisa";
    case VisaType.StudentVisa:
      return "StudentVisa";
    case VisaType.InternshipVisa:
      return "InternshipVisa";
    case VisaType.OtherVisa:
      return "OtherVisa";
    default:
      return "Unknown";
  }
};

export { getVisaTypeLabel };

export default VisaType;
