import React, { useEffect } from "react";
import { useShortenLink } from "../../../Utils/Context/ShortenLinkContext";
import { Button } from "flowbite-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function Delete({ id }) {
  const { destroy, isSuccessDestroy } = useShortenLink();
  const { t } = useTranslation();
  const deleteHandle = async () => {
    await destroy(id);
  };

  useEffect(() => {
    function successHandle() {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      toast.success(t("Shorten link deleted successfully"));
    }
    isSuccessDestroy && successHandle();
  }, [isSuccessDestroy]);

  return (
    <>
      <Button onClick={() => deleteHandle()}>Delete</Button>
    </>
  );
}
