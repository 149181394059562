import { LogoPng } from "../../Assets";
import Card from "../../Components/Card";
import Head from "../../Components/Head";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import SubTitle from "../../Components/SubTitle";
import Title from "../../Components/Title";
import React, { useEffect } from "react";
import { useClient } from "../../Utils/Context/ClientContext";
import Loader from "../../Components/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import MDEditor from "@uiw/react-md-editor";
import { usePlacementTest } from "../../Utils/Context/PlacementTextContex";
import TextInput from "../../Components/TextInput";
import PlacementEnum, { getLabel } from "../../Utils/Enums/Placement";
import Select from "../../Components/Select";
import { useUser } from "../../Utils/Context/UserContext";
import InputError from "../../Components/InputError";
import moment from "moment";

export default function PlacementTestClient() {
  const { client, isLoading, fetchById } = useClient();
  const {
    store,
    update,
    fetchById: getPlacementTest,
    isLoading: loadingPlacement,
    placementTest,
    isSuccess,
    errorMessage,
  } = usePlacementTest();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const placement_id = queryParams.get("placement_id");
  const { fetchTeacher, users: teachers } = useUser();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [note, setNote] = useState(null);
  const [checkedBy, setCheckedBy] = useState(null);
  const [score, setScore] = useState(null);
  const [essay_score, setEssay_score] = useState(null);
  const [placement, setPlacement] = useState(null);

  useEffect(() => {
    isSuccess && navigate(-1);
    fetchById(id);
  }, [isSuccess]);

  useEffect(() => {
    async function getTeacher() {
      await fetchTeacher({});
    }
    getTeacher();
  }, []);

  useEffect(() => {
    if (placement_id) {
      getPlacementTest(placement_id);
    }
  }, [placement_id]);

  useEffect(() => {
    if (placementTest) {
      setNote(placementTest.note ?? null);
      setCheckedBy(placementTest.checked_by?.id ?? null);
      setScore(placementTest.score ?? null);
      setEssay_score(placementTest.essay_score ?? null);
      setPlacement(placementTest.placement ?? null);
    }
  }, [placementTest]);

  const attemptPlacementTest = async (e) => {
    e.preventDefault();
    const dataToStore = {
      user_id: client?.user?.id,
      score: score,
      placement: placement,
    };

    if (note !== null) {
      dataToStore.note = note;
    }
    if (checkedBy !== null) {
      dataToStore.checked_by = checkedBy;
    }
    if (essay_score !== null) {
      dataToStore.essay_score = essay_score;
    }
    if (placement_id) {
      await update(placement_id, dataToStore);
    } else {
      await store(dataToStore);
    }
  };

  return isLoading ? (
    <div className="flex justify-center items-center w-screen h-screen">
      <Loader />
    </div>
  ) : (
    <div className="py-10 h-min-screen dark:bg-gray-700 bg-slate-100 px-5 md:p-32 bg-[url('/src/Assets/bg-landmark-2.svg')] bg-no-repeat bg-contain">
      <Head title="Counselation" />
      <Toaster />
      <Title>{client.user.name}</Title>
      <SubTitle>
        {client.school.name} ({moment(client.graduated_at).format("MMM YYYY")})
      </SubTitle>
      <form onSubmit={attemptPlacementTest}>
        <Card>
          <div className="grid grid-cols-1 gap-3 w-full">
            <div className="flex gap-4">
              <div className="w-full">
                <InputLabel value={t("Score") + "* " + "(score 0 - 40)"} />
                <TextInput
                  type="number"
                  className="w-full"
                  value={score}
                  onChange={(e) => setScore(e.target.value)}
                />
                <InputError
                  message={errorMessage?.["score"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
              <div className="w-full">
                <InputLabel
                  value={t("Essay Score") + " " + "(score 0 - 9.0)"}
                />
                <TextInput
                  className="w-full"
                  value={essay_score}
                  onChange={(e) => setEssay_score(e.target.value)}
                />
                <InputError
                  message={errorMessage?.["essay_score"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
            </div>
            <div>
              <InputLabel value={t("Placement") + "* "} />
              <Select
                id="placement"
                onChange={(e) => {
                  setPlacement(e.target.value);
                }}
                value={placement}
              >
                <Select.Option>{t("Select!")}</Select.Option>
                {Object.entries(PlacementEnum).map(([value, label]) => (
                  <Select.Option key={label} value={label}>
                    {getLabel(label)}
                  </Select.Option>
                ))}
              </Select>
              <InputError
                message={errorMessage?.["placement"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
            <div>
              <InputLabel value={t("Checked by") + "* "} />
              <Select
                id="checked_by"
                onChange={(e) => {
                  setCheckedBy(e.target.value);
                }}
                value={checkedBy}
              >
                <Select.Option>{t("Select!")}</Select.Option>
                {teachers !== null &&
                  teachers.data?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              <InputError
                message={errorMessage?.["checked_by"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
            <div>
              <InputLabel value={t("Note")} />

              <MDEditor
                value={note}
                onChange={setNote}
                className="rounded-3xl p-5"
              />
              <InputError
                message={errorMessage?.["note"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
          </div>
        </Card>
        <div className="flex justify-center">
          <PrimaryButton disabled={loadingPlacement} type={`submit`}>
            {loadingPlacement ? <Loader /> : "Submit"}
          </PrimaryButton>
        </div>
      </form>
      <div className="border-t-slate-500 border-t my-10"></div>
      <div className="flex justify-center">
        <img alt="" src={LogoPng} className="w-full md:w-1/4" />
      </div>
    </div>
  );
}
