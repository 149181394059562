import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useInvoice = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
    isSuccessSend: false,
  });
  const [invoices, setInvoices] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const handleApiError = (e) => {
    setStatus({ isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };

  const handleInitial = () =>
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
      isSuccessSend: false,
    });

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async ({
    page = 1,
    keyword = "",
    limit = 10,
    status = 3,
  }) => {
    const config = {
      params: {
        page: page,
        limit: limit,
        include: ["invoiceClients.invoiceDetails"],
        "filter[invoiceClients.client_name]": keyword,
        "filter[status]": status,
        sort: "-created_at",
      },
    };
    setInvoices(
      await fetchData("get", "invoice", config, undefined, "isSuccessGet")
    );
  };

  const fetchByEmail = async ({ email }) => {
    const config = {
      params: {
        // include: ["user.placementTest", "school"],
        "filter[user.email]": email,
      },
    };
    setInvoices(
      await fetchData("get", "invoice", config, undefined, "isSuccessGet")
    );
  };

  const fetchById = async (id) => {
    const config = {
      params: {
        include: ["invoiceClients.invoiceDetails"],
      },
    };
    setInvoice(
      await fetchData("get", `invoice/${id}`, config, undefined, "isSuccessGet")
    );
  };

  const send = async (invoice_id) => {
    await fetchData(
      "put",
      `invoice/send/${invoice_id}`,
      undefined,
      undefined,
      "isSuccessSend"
    );
  };

  const store = async (data) => {
    setInvoice(
      await fetchData("post", `invoice`, undefined, data, "isSuccess")
    );
  };

  const remove = async (id) => {
    await fetchData(
      "delete",
      `invoice/${id}`,
      undefined,
      undefined,
      "isSuccessDestroy"
    );
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "put",
      `invoice/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  return {
    ...status,
    invoices,
    invoice,
    store,
    remove,
    update,
    fetchAll,
    fetchById,
    fetchByEmail,
    send,
  };
};
