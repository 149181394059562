import Dropdown from "../../../Components/Dropdown";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import moment from "moment";
import { useClient } from "../../../Utils/Context/ClientContext";
import Loader from "../../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Search from "../../../Components/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../Utils/Context/UserContext";
import PopUpModal from "../../../Components/PopUpModal";
import { Pagination } from "flowbite-react";

export default function TableClient({ isToday = false }) {
  const { t } = useTranslation();
  const { clients, fetchAll, isLoading } = useClient();
  const { remove } = useUser();
  const heads = [
    t("No."),
    t("Name"),
    t("Email"),
    t("Phone"),
    t("Last Visit"),
    t("Action"),
  ];
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const removeConfirmation = (item) => {
    setOpenModal(!openModal);
    setUserToDelete(item);
  };

  const removeAttempt = () => {
    remove(userToDelete.user?.id).then(() => {
      fetchAll({ page: page, keyword: keyword, today: isToday });
      setUserToDelete(null);
      setOpenModal(false);
    });
  };

  useEffect(() => {
    fetchAll({ page: page, keyword: keyword, today: isToday });
  }, [page, keyword]);

  return isLoading ? (
    <div className="w-full flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      <PopUpModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        message={
          <>
            <h1>{t("Are you sure?")}</h1>
            <p>{t("This Action cannot be undo.")}</p>
          </>
        }
        action={
          <>
            <PrimaryButton onClick={() => removeAttempt()}>
              {t("Yes")}
            </PrimaryButton>
          </>
        }
      />

      {!isToday && (
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <Search onSubmit={(e) => setKeyword(e.keyword)} />
          <Link to={`/client/register`}>
            <PrimaryButton className="my-5 w-full md:w-fit">
              <p className="w-full text-center">
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {t("Add new client")}
              </p>
            </PrimaryButton>
          </Link>
        </div>
      )}
      <div className="overflow-x-auto">
        <p className="font-bold text-lg text-center md:text-left my-3">
          Total: {clients.total}
        </p>
        <table className="w-full mb-10">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {clients.data?.map((item, index) => {
              const formattedDate = item.last_visit
                ? moment(item.last_visit).calendar(null, {
                    sameDay: "[Today]",
                    sameElse: "LLL",
                  })
                : "No Data";
              return (
                <tr key={index} className={"border-b"}>
                  <TableBody
                    className={"text-center"}
                    children={clients.from + index}
                  />
                  <TableBody>
                    <Link
                      className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                      to={`/client/${item.id}`}
                    >
                      {item.user?.name}
                    </Link>
                  </TableBody>
                  <TableBody children={item.user?.email} />
                  <TableBody children={item.user?.phone} />
                  <TableBody children={formattedDate} />
                  <TableBody
                    className={
                      "text-center p-0 grid cursor-pointer grid-cols-1 md:grid-cols-1 gap-2"
                    }
                  >
                    <Dropdown>
                      <Dropdown.Trigger>...</Dropdown.Trigger>
                      <Dropdown.Content>
                        <Link to={`/client/consultation/${item.id}`}>
                          <PrimaryButton className="flex m-1 w-fit justify-center">
                            <FontAwesomeIcon
                              className="w-4 h-4 mr-3"
                              icon={faPlus}
                            />
                            {t("Add new counsultation")}
                          </PrimaryButton>
                        </Link>
                        <Link to={`/client/placement/${item.id}`}>
                          <PrimaryButton className="flex m-1 w-fit justify-center">
                            <FontAwesomeIcon
                              className="w-4 h-4 mr-3"
                              icon={faPlus}
                            />
                            {t("Add result placement test")}
                          </PrimaryButton>
                        </Link>
                        <Link to={`/client/register?client_id=${item.id}`}>
                          <PrimaryButton className="flex m-1 w-fit justify-center">
                            <FontAwesomeIcon
                              className="w-4 h-4"
                              icon={faEdit}
                            />
                          </PrimaryButton>
                        </Link>
                        <PrimaryButton
                          onClick={() => removeConfirmation(item)}
                          className="flex m-1 w-fit justify-center bg-red-500"
                        >
                          <FontAwesomeIcon className="w-4 h-4" icon={faTrash} />
                        </PrimaryButton>
                      </Dropdown.Content>
                    </Dropdown>
                  </TableBody>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center md:justify-end w-full p-5">
        <Pagination
          className="hidden md:block"
          layout="pagination"
          currentPage={clients.current_page}
          totalPages={clients.last_page}
          onPageChange={(e) => setPage(e)}
        />
        <Pagination
          className="block md:hidden"
          layout="navigation"
          currentPage={clients.current_page}
          totalPages={clients.last_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </>
  );
}
