import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useSchool = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
  });
  const [schools, setSchools] = useState(null);
  const [school, setSchool] = useState(null);

  const handleApiError = (e) => {
    setStatus({ ...status, isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };

  const handleInitial = () => {
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
    });
  };

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async (keyword = "") => {
    const config = {
      params: {
        limit: 300,
        "filter[name]": keyword,
        sort: "name",
      },
    };
    setSchools(
      await fetchData("get", "school", config, undefined, "isSuccessGet")
    );
  };

  const fetch = async ({ keyword = "", page }) => {
    const config = {
      params: {
        "filter[name]": keyword,
        sort: "name",
        page: page,
      },
    };
    setSchools(
      await fetchData("get", `school`, config, undefined, "isSuccessGet")
    );
  };

  const fetchById = async (id) => {
    setSchool(
      await fetchData(
        "get",
        `school/${id}`,
        undefined,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const store = async (data) => {
    setSchool(await fetchData("post", `school`, undefined, data, "isSuccess"));
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "put",
      `school/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  return {
    ...status,
    schools,
    school,
    fetchById,
    fetchAll,
    store,
    fetch,
    update,
  };
};
