import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import Title from "../Components/Title";
import SubTitle from "../Components/SubTitle";
import { LogoPng, NotFoundSvg } from "../Assets";
import { Link } from "react-router-dom";
import Head from "../Components/Head";
import { useTranslation } from "react-i18next";

export default function Error() {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-screen dark:bg-slate-900">
      <Head title="404" />
      <div className="grid grid-cols-1 md:grid-cols-2 p-10">
        <div className="m-auto">
          <img alt="not_found_image" src={NotFoundSvg} className="p-10" />
        </div>
        <div className="m-auto">
          <div className="my-10">
            <Title>{t("Oops! Page is unavailable :(")}</Title>
            <SubTitle>
              {t(
                "Don't worries because we believe that Everyone Can Overseas!"
              )}
            </SubTitle>
          </div>
          <div className="flex justify-center md:justify-start">
            <PrimaryButton>
              <Link to={"/"}>{t("Back to Dashboard")}</Link>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <img alt="logo" src={LogoPng} className="w-1/4" />
      </div>
    </div>
  );
}
