import { LogoPng, BgLandPng, CloudPng } from "../Assets";
import DefaultFooter from "../Sections/DefaultFooter";

export default function Unauthentication({ formIsLeft = false, children }) {
  return (
    <>
      <div className="min-h-screen grid grid-cols-1 md:grid-cols-7 sm:justify-center items-center pt-6 sm:pt-0 bg-gradient-to-b from-sky dark:from-sky to-white dark:to-slate-700 dark:bg-gray-900">
        <div
          className={`md:col-span-4 flex justify-center md:justify-start items-start ${
            formIsLeft ? "flex md:hidden" : "hidden md:flex"
          }`}
        >
          <img
            src={LogoPng}
            alt="Logo"
            className="hidden md:block md:w-44 md:h-auto absolute top-0 left-0 md:ml-6 mt-6 px-20 md:px-0"
          />
          <img
            src={CloudPng}
            alt="Cloud"
            className="w-full md:w-1/3 h-auto absolute top-28 left-10 animate-floating-cloud"
          />
          <img
            src={BgLandPng}
            alt="Background"
            className="w-1/2 md:w-1/3 md:left-44 absolute md:top-28 top-5 animate-floating"
          />
          <img
            src={CloudPng}
            alt="Cloud"
            className="w-full md:w-1/2 h-auto absolute top-72 left-10 animate-floating-cloud-2"
          />
        </div>
        <div className="w-full flex justify-center items-center md:col-span-3 mt-6 px-6 py-4 overflow-hidden relative">
          {children}
        </div>
        <div
          className={`md:col-span-4 flex justify-center md:justify-start items-start ${
            formIsLeft ? "hidden md:flex" : "flex md:hidden"
          }`}
        >
          <img
            src={LogoPng}
            alt="Logo"
            className="hidden md:block md:w-44 md:h-auto absolute top-0 left-0 md:ml-6 md:mt-6 px-20 md:px-0"
          />
          <img
            src={CloudPng}
            alt="Cloud"
            className="hidden md:block md:w-1/3 h-auto absolute top-28 right-10 animate-floating-cloud"
          />
          <img
            src={BgLandPng}
            alt="Background"
            className="w-3/4 hidden md:block md:w-1/2 md:-right-36 absolute md:top-28 -bottom-52 animate-floating"
          />
          <img
            src={CloudPng}
            alt="Cloud"
            className="hidden md:block md:w-1/2 h-auto absolute top-72 right-10 animate-floating-cloud-2"
          />
        </div>
        <style>{`
            @keyframes floating {
                0% { transform: translateY(0); }
                50% { transform: translateY(-30px); }
                100% { transform: translateY(0); }
            }
            @keyframes floating-cloud {
                0% { transform: translateX(0); }
                50% { transform: translateX(-90px); }
                100% { transform: translateX(0); }
            }
            @keyframes floating-cloud-2 {
                0% { transform: translateX(0); }
                50% { transform: translateX(90px); }
                100% { transform: translateX(0); }
            }

            .animate-floating-cloud { animation: floating-cloud 60s ease-in-out infinite; }
            .animate-floating-cloud-2 { animation: floating-cloud-2 90s ease-in-out infinite; }
            .animate-floating { animation: floating 10s ease-in-out infinite; }
          `}</style>
      </div>
      <DefaultFooter />
    </>
  );
}
