const StatusInvoice = {
  VOID: 0,
  UNPAID: 1,
  PAID: 2,
  // UNCONFIRMED: 3,
};

const getLabel = (x) => {
  switch (x) {
    case StatusInvoice.VOID:
      return "VOID";
    case StatusInvoice.UNPAID:
      return "UNPAID";
    case StatusInvoice.PAID:
      return "PAID";
    // case StatusInvoice.UNCONFIRMED:
    //   return "UNCONFIRMED";
    default:
      return "Unknown";
  }
};

export { getLabel };

export default StatusInvoice;
