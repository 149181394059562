import React from "react";
import SubTitle from "./SubTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ListWithIcon({ icon, children }) {
  return (
    <SubTitle>
      <div className="flex">
        <div>
          <FontAwesomeIcon icon={icon} className="mr-5 dark:text-slate-200" />
        </div>
        <div className="flex-1 dark:text-slate-200">{children}</div>
      </div>
    </SubTitle>
  );
}
