import { useEffect } from "react";
import { APP_NAME } from "../Utils/Constant";

function Head(props) {
  useEffect(() => {
    document.title = `${props.title} - ${APP_NAME}`;
  }, [props.title]);

  return null;
}

export default Head;
