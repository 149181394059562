import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { supabase } from "../Network/Supabase";

export const useShortenLink = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
  });
  const [shortenLinks, setShortenLinks] = useState(null);
  const [shortenLink, setShortenLink] = useState(null);

  const handleApiError = (e) => {
    setStatus({ ...status, isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };

  const handleInitial = () => {
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
    });
  };

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      let { data: shorten_links, error } = await supabase
        .from("shorten_links")
        .select("*");

      handleFinish(successType);

      return shorten_links;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async (keyword = "") => {
    let { data: shorten_links, error } = await supabase
      .from("shorten_links")
      .select("*");

    setShortenLinks(shorten_links);
  };

  const fetch = async ({ keyword = "", page }) => {
    const config = {
      params: {
        "filter[name]": keyword,
        sort: "name",
        page: page,
      },
    };
    setShortenLinks(
      await fetchData("get", `shortenLink`, config, undefined, "isSuccessGet")
    );
  };

  const fetchById = async (id) => {
    setShortenLink(
      await fetchData(
        "get",
        `shortenLink/${id}`,
        undefined,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const store = async (data) => {
    try {
      // Attempt to sign in with Supabase

      const { data: login, error: errorLogin } =
        await supabase.auth.signInWithPassword({
          email: "shorten@link",
          password: "shortenlink_kv",
        });

      if (errorLogin) {
        throw new Error("Login failed: " + errorLogin.message);
      }

      // Only proceed if login was successful
      if (login) {
        const { data: shorten, error: errorShorten } = await supabase
          .from("shorten_links")
          .insert(data)
          .select();

        if (errorShorten) {
          throw new Error(
            "Short link insertion failed: " + errorShorten.message
          );
        }

        handleFinish("isSuccess");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const destroy = async (id) => {
    const { error } = await supabase
      .from("shorten_links")
      .delete()
      .eq("id", id);

    if (error) {
      handleApiError(error);
      return false;
    }
    handleFinish("isSuccessDestroy");
  };

  return {
    ...status,
    shortenLinks,
    shortenLink,
    fetchById,
    fetchAll,
    store,
    fetch,
    destroy,
  };
};
