import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Dropdown from "../Components/Dropdown";
import ResponsiveNavLink from "../Components/ResponsiveNavLink";
import { faBell, faWarning } from "@fortawesome/free-solid-svg-icons";
import { SIDEBAR_MENU } from "../Utils/Constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUpModal from "../Components/PopUpModal";
import SecondaryButton from "../Components/SecondaryButton";
import PrimaryButton from "../Components/PrimaryButton";
import { useAuth } from "../Utils/Context/AuthContext";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import RemoveSession from "../Utils/Tools/RemoveSession";

export default function Authentication({ user, header, children, callback }) {
  const { t } = useTranslation();
  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { logout, isSuccess, isError, isLoading } = useAuth();
  const navigate = useNavigate();
  const userRoles = JSON.parse(sessionStorage.getItem("roles"));

  useEffect(() => {
    isSuccess && successHandle();
    isError && errorHandle();
  }, [isSuccess, isError]);

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
  }, []);

  const getInitials = (name) => name?.charAt(0).toUpperCase();

  const confirmLogout = () => setIsOpenModal(true);
  const attemptLogout = async () => await logout();
  const successHandle = () => handleLogout();
  const errorHandle = () => handleLogout();

  const handleLogout = () => {
    RemoveSession();
    navigate("/login");
  };

  return (
    <div className="w-full flex">
      <Toaster />
      <Sidebar />
      <PopUpModal
        isOpen={isOpenModal}
        message={`Are you sure to logout?`}
        icon={faWarning}
        onClose={() => setIsOpenModal(false)}
        action={
          <div className="flex gap-3">
            <SecondaryButton onClick={() => setIsOpenModal(false)}>
              {t("Cancel")}
            </SecondaryButton>
            <PrimaryButton
              disabled={isLoading}
              onClick={attemptLogout}
              className="bg-red-500 hover:bg-red-900"
            >
              {t("Logout")}
            </PrimaryButton>
          </div>
        }
      />
      <div className="ml-0 md:ml-64 flex-1 bg-gray-100 dark:bg-gray-900">
        <nav className="bg-white dark:bg-gray-800 border-b border-gray-100 dark:border-gray-700">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex"></div>
              <div className="hidden sm:flex sm:items-center sm:ml-6">
                <div className="ml-3 relative">
                  <Dropdown>
                    <Dropdown.Trigger>
                      <span className="inline-flex rounded-md">
                        <button
                          type="button"
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none transition ease-in-out duration-150"
                        >
                          {userData?.name}
                          <svg
                            className="ml-2 -mr-0.5 h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>
                    </Dropdown.Trigger>

                    <Dropdown.Content>
                      <Dropdown.Link to={`/profile`}>
                        {t("Profile")}
                      </Dropdown.Link>
                      <Dropdown.Link
                        className="text-red-500 font-bold"
                        onClick={confirmLogout}
                      >
                        {t("Logout")}
                      </Dropdown.Link>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
                <div className="avatar flex items-center justify-center w-8 h-8 bg-primary rounded-full">
                  <span className="text-white text-xl font-bold">
                    {getInitials(userData?.name)}
                  </span>
                </div>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <button
                  onClick={() =>
                    setShowingNavigationDropdown(
                      (previousState) => !previousState
                    )
                  }
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-900 focus:text-gray-500 dark:focus:text-gray-400 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={
                        !showingNavigationDropdown ? "inline-flex" : "hidden"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                    <path
                      className={
                        showingNavigationDropdown ? "inline-flex" : "hidden"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div
            className={
              (showingNavigationDropdown ? "block" : "hidden") + " sm:hidden"
            }
          >
            <div className="pt-2 pb-3 space-y-1">
              {SIDEBAR_MENU.map((item, index) => {
                const isAllowed = userRoles.some((userRole) =>
                  item.allowedRoles.includes(userRole.name)
                );
                return (
                  isAllowed && (
                    <ResponsiveNavLink
                      key={index}
                      href={item.route}
                      active={location.pathname === item.route ?? false}
                    >
                      {item.title}
                    </ResponsiveNavLink>
                  )
                );
              })}
            </div>
            <div className="pt-4 pb-1 border-t border-gray-200 dark:border-gray-600">
              <div className="px-4">
                <div className="font-medium text-base text-gray-800 dark:text-gray-200">
                  {userData?.name}
                </div>
                <div className="font-medium text-sm text-gray-500">
                  {userData?.email}
                </div>
              </div>
              <div className="mt-3 space-y-1 grid grid-cols-1 px-4">
                <Link className="dark:text-slate-300" to={`/profile`}>
                  {t("Profile")}
                </Link>
                <Link
                  className="text-red-500 font-bold"
                  onClick={confirmLogout}
                >
                  {t("Logout")}
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {header && (
          <header className="bg-white dark:bg-gray-800 shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              {header}
            </div>
          </header>
        )}
        <main className="min-h-screen overflow-y-auto">{children}</main>
      </div>
    </div>
  );
}
