import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import VisaType, { getVisaTypeLabel } from "../../Utils/Enums/Visa";
import Select2 from "../../Components/Select2";
import { useProgram } from "../../Utils/Context/ProgramContext";
import { useLocation, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import BeginPage from "../../Components/BeginPage";
import TablePayable from "../Payable/Partials/TablePayable";

export default function Create() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const program_id = queryParams.get("id");

  const { t } = useTranslation();
  const { store, isSuccess, isSuccessUpdate, fetchById, program, update } =
    useProgram();
  const navigate = useNavigate();

  const [visa, setVisa] = useState(program?.visa ?? null);
  const [name, setName] = useState(program?.name ?? null);
  const [description, setDescription] = useState(program?.description ?? "");
  const [code, setCode] = useState(program?.code ?? null);
  // const [image, setImage] = useState(null);

  useEffect(() => {
    async function getData() {
      await fetchById(program_id);
    }
    if (program_id) {
      getData();
    }
  }, [program_id]);

  useEffect(() => {
    if (program) {
      setName(program.name ?? "");
      setVisa(program.visa ?? "");
      setDescription(program.description ?? "");
      setCode(program.code ?? "");
    }
  }, [program]);

  const storeExe = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("visa", visa);
    formData.append("name", name);
    if (description && description.trim() !== "") {
      formData.append("description", description);
    }
    formData.append("code", code);

    // formData.append("image", image);

    if (program) {
      await update(program_id, formData);
    } else {
      await store(formData);
    }
  };

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Program added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);
  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Program has been updated"));
    }
    isSuccessUpdate && successHandle();
  }, [isSuccessUpdate]);

  return (
    <Authentication header={<Header>{t("Program")}</Header>}>
      <Head title={t("Program")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <form
            className="w-full"
            onSubmit={storeExe}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div>
                <InputLabel>{t("Program Name")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <InputLabel>{t("Visa Category")}</InputLabel>
                {program &&
                  `${t("choosed")}: ${t("VisaType_" + getVisaTypeLabel(visa))}`}
                <Select2
                  options={Object.entries(VisaType)}
                  onSelected={(e) => setVisa(e[1])}
                  onTextChanged={() => null}
                  translate={`VisaType_`}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div>
                <InputLabel>{t("Code")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div>
                <InputLabel>{t("Description")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {/* <div>
                <InputLabel>{t("Image")}</InputLabel>
                <input
                  className="w-full"
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div> */}
            </div>
            <div className="flex justify-end">
              <PrimaryButton className="my-5 w-full md:w-fit">
                <p className="w-full text-center">Submit</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>

      {program && (
        <BeginPage>
          <h1 className="text-2xl font-bold">{program.name}</h1>
          <p>{t("Payable list")}</p>
          <TablePayable program_id={program_id} />
        </BeginPage>
      )}
    </Authentication>
  );
}
