import Dropdown from "../../../Components/Dropdown";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import Loader from "../../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Search from "../../../Components/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../Utils/Context/UserContext";
import PopUpModal from "../../../Components/PopUpModal";
import { Pagination } from "flowbite-react";

export default function TableUser() {
  const { t } = useTranslation();
  const { users, fetchAll, isLoading, remove } = useUser();
  const heads = [t("No."), t("Name"), t("Email"), t("Action")];
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const removeConfirmation = (item) => {
    setOpenModal(!openModal);
    setUserToDelete(item);
  };

  const removeAttempt = () => {
    remove(userToDelete.id).then(() => {
      fetchAll({ page: page, keyword: keyword });
      setUserToDelete(null);
      setOpenModal(false);
    });
  };

  useEffect(() => {
    fetchAll({ page: page, keyword: keyword });
  }, [page, keyword]);

  return isLoading ? (
    <div className="w-ful flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      <PopUpModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        message={
          <>
            <h1>Are you sure?</h1>
            <p>This Action cannot be undo.</p>
          </>
        }
        action={
          <>
            <PrimaryButton onClick={() => removeAttempt()}>
              {t("Yes")}
            </PrimaryButton>
          </>
        }
      />
      <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
        <Search onSubmit={(e) => setKeyword(e.keyword)} />
        <Link to={`/user/add`}>
          <PrimaryButton className="my-5 w-full md:w-fit">
            <p className="w-full text-center">
              <FontAwesomeIcon className="mr-3" icon={faPlus} />
              {t("Add New Admin")}
            </p>
          </PrimaryButton>
        </Link>
      </div>
      <div className="overflow-x-auto">
        <p className="font-bold text-lg text-center md:text-left my-3">
          Total: {users.total}
        </p>
        <table className="w-full mb-10">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {users.data.map((item, index) => {
              return (
                <tr key={index} className={"border-b"}>
                  <TableBody
                    className={"text-center"}
                    children={users.from + index}
                  />
                  <TableBody>
                    <Link
                      className="hover:bg-slate-400 px-5 py-2 transition-all duration-500 rounded-full"
                      to={`/user/${item.id}`}
                    >
                      {item.name}
                    </Link>
                  </TableBody>
                  <TableBody children={item.email} />
                  <TableBody
                    className={
                      "text-center p-0 grid cursor-pointer grid-cols-1 md:grid-cols-1 gap-2"
                    }
                  >
                    <Dropdown>
                      <Dropdown.Trigger>...</Dropdown.Trigger>
                      <Dropdown.Content>
                        <Link to={`/user/${item.id}`}>
                          <PrimaryButton className="flex m-1 w-fit justify-center">
                            <FontAwesomeIcon
                              className="w-4 h-4"
                              icon={faEdit}
                            />
                          </PrimaryButton>
                        </Link>
                        <PrimaryButton
                          onClick={() => removeConfirmation(item)}
                          className="flex m-1 w-fit justify-center bg-red-500"
                        >
                          <FontAwesomeIcon className="w-4 h-4" icon={faTrash} />
                        </PrimaryButton>
                      </Dropdown.Content>
                    </Dropdown>
                  </TableBody>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center md:justify-end w-full p-5">
        <Pagination
          className="hidden md:block"
          layout="pagination"
          currentPage={users.current_page}
          totalPages={users.last_page}
          onPageChange={(e) => setPage(e)}
        />
        <Pagination
          className="block md:hidden"
          layout="navigation"
          currentPage={users.current_page}
          totalPages={users.last_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </>
  );
}
