const StatusCode = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  ACCOUNT_ALREADY_REGISTERED: 210,
};

const getMessage = (x) => {
  switch (x) {
    case StatusCode.SUCCESS:
      return "Success";
    case StatusCode.NOT_FOUND:
      return "Not Found";
    case StatusCode.SERVER_ERROR:
      return "Server Error";
    case StatusCode.ACCOUNT_ALREADY_REGISTERED:
      return "Your email has been registered";
    default:
      return "Unknown";
  }
};

const getColor = (x) => {
  switch (x) {
    case StatusCode.SUCCESS:
      return "success";
    case StatusCode.NOT_FOUND:
      return "failure";
    case StatusCode.SERVER_ERROR:
      return "failure";
    case StatusCode.ACCOUNT_ALREADY_REGISTERED:
      return "info";
    default:
      return "Unknown";
  }
};

export { getMessage, getColor };

export default StatusCode;
