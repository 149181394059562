import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Error from "./Pages/Error";
import { Login, Register, ForgotPassword } from "./Pages/Auth/Index";
import Client, {
  ClientCheck,
  ClientConsultation,
  ClientCreate,
  ClientPlacementTest,
  ClientProgram,
  ClientShow,
} from "./Pages/Client/Index";
import Program, { ProgramCreate } from "./Pages/Program/Index";
import PrivateRoute from "./Utils/Auth/PrivateRoute";
import PublicRoute from "./Utils/Auth/PublicRoute";
import User, { UserCreate, UserEdit, UserShow } from "./Pages/User/Index";
import OurServices from "./Pages/OurServices";
import Profile from "./Pages/Profile/Index";
import Invoice, {
  InvoiceCreate,
  InvoicePrint,
  InvoiceShow,
} from "./Pages/Finance/Invoice/Index";
import Payable, { PayableCreate } from "./Pages/Payable/Index";
import School, { SchoolCreate } from "./Pages/School/Index";
import Template, { TemplateCreate } from "./Pages/Template/Index";
import EditMetadata from "./Pages/Client/Partials/EditMetadata";
import ShortenLink, { ShortenLinkCreate } from "./Pages/ShortenLink";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Error />} />
        <Route path="our-services" element={<OurServices />} />
        <Route path="/client/add" element={<ClientCheck />} />
        <Route path="/client/register" element={<ClientCreate />} />
        {/* Public */}
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        {/* Private */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={["super-admin", "admin", "client", "counselor"]}
            />
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route
          element={
            <PrivateRoute
              allowedRoles={["super-admin", "admin", "counselor"]}
            />
          }
        >
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/invoice/create" element={<InvoiceCreate />} />
          <Route path="/invoice/print/:id" element={<InvoicePrint />} />
          <Route path="/invoice/:id" element={<InvoiceShow />} />
          <Route path="/client" element={<Client />} />
          <Route path="/client/:id" element={<ClientShow />} />
          <Route
            path="/client/consultation/:id"
            element={<ClientConsultation />}
          />
          <Route path="/shortenlink" element={<ShortenLink />} />
          <Route path="/shortenlink/create" element={<ShortenLinkCreate />} />
          <Route path="/client/metadata/:id" element={<EditMetadata />} />
          <Route path="/client/program/:id" element={<ClientProgram />} />
          <Route
            path="/client/placement/:id"
            element={<ClientPlacementTest />}
          />
        </Route>
        <Route element={<PrivateRoute allowedRoles={["super-admin"]} />}>
          <Route path="/user" element={<User />} />
          <Route path="/user/:id" element={<UserShow />} />
          <Route path="/user/edit/:id" element={<UserEdit />} />
          <Route path="/user/add" element={<UserCreate />} />
          <Route path="/program" element={<Program />} />
          <Route path="/program/create" element={<ProgramCreate />} />
          <Route path="/payable" element={<Payable />} />
          <Route path="/payable/create" element={<PayableCreate />} />
          <Route path="/school" element={<School />} />
          <Route path="/school/create" element={<SchoolCreate />} />
          <Route path="/template" element={<Template />} />
          <Route path="/template/create" element={<TemplateCreate />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
