import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useTemplate = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
  });
  const [templates, setTemplates] = useState(null);
  const [template, setTemplate] = useState(null);

  const handleApiError = (e) => {
    setStatus({ ...status, isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };

  const handleInitial = () => {
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
    });
  };

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async (keyword = "") => {
    const config = {
      params: {
        "filter[name]": keyword,
      },
    };
    setTemplates(
      await fetchData(
        "get",
        "consultation_template",
        config,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const fetch = async ({ keyword = "", page }) => {
    const config = {
      params: {
        "filter[name]": keyword,
        page: page,
      },
    };
    setTemplates(
      await fetchData(
        "get",
        `consultation_template`,
        config,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const fetchById = async (id) => {
    setTemplate(
      await fetchData(
        "get",
        `consultation_template/${id}`,
        undefined,
        undefined,
        "isSuccessGet"
      )
    );
  };

  const store = async (data) => {
    setTemplate(
      await fetchData(
        "post",
        `consultation_template`,
        undefined,
        data,
        "isSuccess"
      )
    );
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "patch",
      `consultation_template/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  const destroy = async (id) => {
    const result = await fetchData(
      "delete",
      `consultation_template/${id}`,
      undefined,
      undefined,
      "isSuccessDestroy"
    );
    return result;
  };

  return {
    ...status,
    templates,
    template,
    fetchById,
    fetchAll,
    store,
    fetch,
    update,
    destroy,
  };
};
