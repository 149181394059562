import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import RemoveSession from "../Tools/RemoveSession";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const PrivateRoute = ({ allowedRoles, ...props }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const userRoles = JSON.parse(sessionStorage.getItem("roles"));

  if (token) {
    if (
      userRoles &&
      userRoles.some((role) => allowedRoles.includes(role.name))
    ) {
      return <Outlet />;
    } else {
      toast.error(t("Your account doesn't have the required role"));
      RemoveSession();
    }
  }
  return <Navigate state={{ from: location.pathname }} to="/login" replace />;
};

export default PrivateRoute;
