import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import TextInput from "./TextInput";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select2({
  options,
  onSelected,
  onTextChanged,
  translate,
  isSearchable = false,
  reset = false,
}) {
  const defaultOption = { name: "Select..." };
  const [selected, setSelected] = useState(defaultOption);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (reset) {
      setSelected(defaultOption);
      setSearch("");
    }
  }, [reset]);

  useEffect(() => {
    onSelected(selected);
  }, [selected]);

  useEffect(() => {
    onTextChanged(search);
  }, [search]);

  const { t } = useTranslation();

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6 border-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:ring-primary rounded-3xl shadow-sm">
              <span className="flex items-center">
                <span className="ml-3 block truncate ">
                  {selected.name ||
                    selected?.user?.name ||
                    t(translate + selected[0])}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute border-2 z-10 mt-1 max-h-56 w-full overflow-auto border-primary rounded-3xl dark:border-slate-300 bg-white dark:bg-slate-900 py-3 px-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <TextInput
                  type="search"
                  placeholder="Search..."
                  className={`w-full my-3 ${isSearchable ? "block" : "hidden"}`}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                {options?.map((option, index) => (
                  // <Tooltip
                  //   content={
                  //     option?.name ||
                  //     option?.user?.name ||
                  //     t(translate + option[0])
                  //   }
                  // >
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-primary rounded-3xl text-white"
                          : "text-gray-900 dark:text-slate-200",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {option?.name ||
                              option?.user?.name ||
                              t(translate + option[0])}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                  // </Tooltip>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
