import React from "react";
import Head from "../../../Components/Head";
import Header from "../../../Components/Header";
import BeginPage from "../../../Components/BeginPage";
import { useTranslation } from "react-i18next";
import Authentication from "../../../Layouts/Authentication";
import TableInvoice from "./Partials/TableInvoice";
import InvoiceCreate from "./Create";
import InvoiceShow from "./Show";
import InvoicePrint from "./Print";

export { InvoiceCreate, InvoiceShow, InvoicePrint };

export default function Invoice() {
  const { t } = useTranslation();
  return (
    <Authentication header={<Header>{t("Invoice")}</Header>}>
      <Head title={t("Invoice")} />
      <BeginPage>
        <TableInvoice />
      </BeginPage>
    </Authentication>
  );
}
