import React from "react";
import Guest from "../../Layouts/Guest";
import TextInput from "../../Components/TextInput";
import PrimaryButton from "../../Components/PrimaryButton";
import InputError from "../../Components/InputError";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  return (
    <Guest>
      <Head title={t("Forgot password")} />

      <div className="mb-4 text-sm text-gray-600 dark:text-gray-400">
        {t(
          "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one."
        )}
      </div>

      <form>
        <TextInput
          id="email"
          type="email"
          name="email"
          className="mt-1 block w-full"
          isFocused={true}
        />

        <InputError message={``} className="mt-2" />

        <div className="flex items-center justify-end mt-4">
          <PrimaryButton className="ml-4" s>
            {t("Email Password Reset Link")}
          </PrimaryButton>
        </div>
      </form>
    </Guest>
  );
}
