import React from "react";
import StatusInvoice from "../../../../Utils/Enums/StatusInvoice";
import { useTranslation } from "react-i18next";

export default function Status({ invoice }) {
  const { t } = useTranslation();
  let statusComponent;

  switch (invoice?.status) {
    case StatusInvoice.PAID:
      statusComponent = (
        <p className="text-green-300 font-bold text-2xl">{t("PAID")}</p>
      );
      break;
    case StatusInvoice.UNCONFIRMED:
      statusComponent = (
        <p className="text-red-300 font-bold text-2xl">{t("UNCONFIRMED")}</p>
      );
      break;
    case StatusInvoice.UNPAID:
      statusComponent = (
        <p className="text-yellow-300 font-bold text-2xl">{t("UNPAID")}</p>
      );
      break;
    case StatusInvoice.VOID:
      statusComponent = (
        <p className="text-black-300 font-bold text-2xl">{t("VOID")}</p>
      );
      break;
    default:
      statusComponent = (
        <p className="text-red-300 font-bold text-2xl">{t("UNKNOWN")}</p>
      );
      break;
  }

  return statusComponent;
}
