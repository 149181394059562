import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useAuth = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [auth, setAuth] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleError = (e) => {
    setIsError(true);
    setError(e);
    setErrorMessage(e.response?.data?.errors);
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = () => {
    setIsLoading(false);
  };
  const handleInitial = () => {
    setIsLoading(true);
  };

  const login = async (data) => {
    handleInitial();
    await instanceRequest("post", "auth/login", undefined, data)
      .then((r) => {
        setIsSuccess(true);
        setAuth(r);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const logout = async () => {
    setIsLoading(true);
    await instanceRequest("delete", "auth/logout", undefined, undefined)
      .then(() => setIsSuccess(true))
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const register = async (data) => {
    handleInitial();
    await instanceRequest("post", "auth/register", undefined, data)
      .then((r) => {
        setAuth(r);
        setIsSuccess(true);
        toast.success(t("Your ragistration has successful"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const updatePassword = async (data) => {
    handleInitial();
    await instanceRequest("post", "password/update", undefined, data)
      .then((r) => {
        setAuth(r);
        setIsSuccess(true);
        toast.success(t("Your password has been updated"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  return {
    login,
    logout,
    register,
    updatePassword,
    isLoading,
    isError,
    isSuccess,
    auth,
    error,
    errorMessage,
  };
};
