import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  return sessionStorage.getItem("token") !== null ? (
    <Navigate to="/" replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
