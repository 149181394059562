import React from "react";
import DefaultFooter from "../Sections/DefaultFooter";

export default function BeginPage({ children, className }) {
  return (
    <>
      <div
        className={`p-10 w-screen min-h-screen md:w-auto dark:text-slate-200 ${className}`}
      >
        {children}
      </div>
      <DefaultFooter />
    </>
  );
}
