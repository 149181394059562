import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../../Components/PrimaryButton";
import Authenticated from "../../../Layouts/Authentication";
import Header from "../../../Components/Header";
import Head from "../../../Components/Head";
import BeginPage from "../../../Components/BeginPage";
import { useTranslation } from "react-i18next";
import TextInput from "../../../Components/TextInput";
import Card from "../../../Components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useClient } from "../../../Utils/Context/ClientContext";
import toast from "react-hot-toast";

export default function EditMetadata() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { client, fetchById, update, isSuccessUpdate } = useClient();
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    fetchById(id);
  }, [id]);

  useEffect(() => {
    if (client) setJsonData(JSON.parse(client.metadata) || []);
  }, [client]);

  useEffect(() => {
    if (isSuccessUpdate) toast.success(t("Metadata has been updated"));
  }, [isSuccessUpdate]);

  const handleSubmit = async () => {
    const formdata = new FormData();
    formdata.append("client[metadata]", JSON.stringify(jsonData));
    await update(id, formdata);
  };

  const handleChange = (index, key, value, subIndex) => {
    const newJsonData = [...(jsonData || [])];
    const item = newJsonData[index];
    if (!item) return;

    if (key === "name") {
      item.name = value;
    } else if (key === "value") {
      if (subIndex !== undefined) {
        const subItem = item.list[subIndex] || {};
        item.list[subIndex] = { ...subItem, value };
      } else {
        item.value = value;
      }
    } else if (key === "subName") {
      const subItem = item.list[subIndex] || {};
      item.list[subIndex] = { ...subItem, name: value };
    }

    setJsonData(newJsonData);
  };

  const addNewItem = () => {
    setJsonData((jsonData || []).concat({ name: "", list: [], value: "" }));
  };

  const addNewSubItem = (index) => {
    setJsonData(
      (jsonData || []).map((item, i) =>
        i === index
          ? { ...item, list: (item.list || []).concat({ name: "", value: "" }) }
          : item
      )
    );
  };

  const removeItem = (index) => {
    setJsonData((jsonData || []).filter((_, i) => i !== index));
  };

  const removeSubItem = (index, subIndex) => {
    setJsonData(
      (jsonData || []).map((item, i) =>
        i === index
          ? {
              ...item,
              list: (item.list || []).filter((_, j) => j !== subIndex),
            }
          : item
      )
    );
  };

  return (
    <Authenticated header={<Header>{t("Metadata")}</Header>}>
      <Head title={t("Metadata")} />
      <BeginPage>
        <div className="p-4">
          {(jsonData || []).map((item, index) => (
            <Card key={index}>
              <div className="relative w-full h-full">
                {/* close button */}
                <div className="absolute top-0 right-0">
                  <PrimaryButton
                    className="p-2 rounded-full bg-red-300 dark:bg-red-400"
                    onClick={() => removeItem(index)}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </PrimaryButton>
                </div>

                {/* name and value input */}
                <div className="flex flex-col gap-2 mt-12">
                  <TextInput
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleChange(index, "name", e.target.value)
                    }
                    className="mb-2 p-2 border border-gray-300 rounded-md"
                    placeholder="Item Name"
                  />
                  <TextInput
                    type="text"
                    value={item.value}
                    onChange={(e) =>
                      handleChange(index, "value", e.target.value)
                    }
                    className="mb-2 p-2 border border-gray-300 rounded-md"
                    placeholder="Item Value"
                  />
                </div>

                {/* sub item input */}
                <div className="">
                  {(item.list || []).map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className="flex items-center mb-2 top-0 right-0"
                    >
                      <TextInput
                        type="text"
                        value={subItem.name}
                        onChange={(e) =>
                          handleChange(
                            index,
                            "subName",
                            e.target.value,
                            subIndex
                          )
                        }
                        className="mr-2 p-2 border border-gray-300 rounded-md"
                        placeholder="Sub Item Name"
                      />
                      <TextInput
                        type="text"
                        value={subItem.value}
                        onChange={(e) =>
                          handleChange(index, "value", e.target.value, subIndex)
                        }
                        className="mr-2 p-2 border border-gray-300 rounded-md"
                        placeholder="Sub Item Value"
                      />
                      <PrimaryButton
                        className="p-2 rounded-full bg-red-300 dark:bg-red-400"
                        onClick={() => removeSubItem(index, subIndex)}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </PrimaryButton>
                    </div>
                  ))}
                </div>

                {/* add sub item button */}
                <PrimaryButton onClick={() => addNewSubItem(index)}>
                  Add Sub Item
                </PrimaryButton>
              </div>
            </Card>
          ))}
          <PrimaryButton onClick={addNewItem} className="mr-2">
            Add New Item
          </PrimaryButton>

          {(jsonData || []).length > 0 && (
            <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
          )}
        </div>
      </BeginPage>
    </Authenticated>
  );
}
