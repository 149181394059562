import React, { useEffect, useState } from "react";
import { LogoPng } from "../../../Assets";
import { useParams } from "react-router-dom";
import { useInvoice } from "../../../Utils/Context/InvoiceContext";
import { Table } from "flowbite-react";
import moment from "moment";

export default function Show() {
  const { id } = useParams();
  const { fetchById, invoice } = useInvoice();
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    fetchById(id);
  }, []);

  useEffect(() => {
    if (invoice) {
      // Calculate grand total when invoice changes
      let total = 0;
      invoice.invoice_clients.forEach((ic) => {
        ic.invoice_details.forEach((id) => {
          total += id.unit_price * id.qty;
        });
      });
      setGrandTotal(total);
    }
  }, [invoice]);

  let rowNumber = 0;
  return (
    <div className="p-10">
      <div className="h-36 flex justify-center">
        <img className="w-auto h-full" src={LogoPng} />
      </div>
      <div className="flex justify-center">
        <p className="text-3xl font-bold text-slate-900 underline">
          {invoice?.number}
        </p>
      </div>
      <div className="mt-10">
        <div className="text-xl">
          <p>
            Invoice to:{" "}
            {invoice?.invoice_clients?.map((client, index) => (
              <p className="font-bold" key={index}>{`${client.client_name}`}</p>
            ))}
          </p>
        </div>
        <div className="mt-3">
          <p>
            Invoice Date:{" "}
            <span className="font-bold">
              {moment(invoice?.created_at).format("LLL")}
            </span>
          </p>
        </div>
        <div>
          <p>
            Due Date:{" "}
            <span className="font-bold">
              {moment(invoice?.due).format("LL")}
            </span>
          </p>
        </div>
      </div>

      <div className="mt-10">
        <Table>
          <Table.Head>
            <Table.HeadCell>No.</Table.HeadCell>
            <Table.HeadCell>Description</Table.HeadCell>
            <Table.HeadCell>Quantity</Table.HeadCell>
            <Table.HeadCell>Amount</Table.HeadCell>
            <Table.HeadCell>Total</Table.HeadCell>
          </Table.Head>
          {invoice !== null &&
            invoice.invoice_clients.map((ic, i) => (
              <Table.Body key={i}>
                {ic.invoice_details.map((id, ix) => (
                  <Table.Row>
                    <>
                      <Table.Cell>{++rowNumber}</Table.Cell>
                      <Table.Cell>{`${ic.program_name} - ${id.item_name}`}</Table.Cell>
                      <Table.Cell>{id.qty}</Table.Cell>
                      <Table.Cell>{id.unit_price}</Table.Cell>
                      <Table.Cell>{id.unit_price * id.qty}</Table.Cell>
                    </>
                  </Table.Row>
                ))}
              </Table.Body>
            ))}

          <Table.Body>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>Grand Total</Table.Cell>
              <Table.Cell>{grandTotal}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="mt-10">
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Account Name:</Table.Cell>
              <Table.Cell>CV KONSULTAN VISA</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Address:</Table.Cell>
              <Table.Cell>
                Jl. Gatot Subroto Barat 108X Denpasar Bali Indonesia
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Bank Name:</Table.Cell>
              <Table.Cell>Commonwealth Bank of Indonesia</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Account Name:</Table.Cell>
              <Table.Cell>CV KONSULTAN VISA</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Bank Address:</Table.Cell>
              <Table.Cell>Jl. Teuku Umar Denpasar Bali Indonesia</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Account Number: <br></br>(Currency):
              </Table.Cell>
              <Table.Cell>
                2700008343 (IDR) Indonesian Rupiah <br></br> 2700008344
                (AUD)Australian Dollar <br></br> 2700008345 (SGD) Singapore
                Dollar <br></br> 2700008346 (USD)US Dollar
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Bank Swift Code:</Table.Cell>
              <Table.Cell>BICNIDJA</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="text-center mt-10">
        <p>KONSULTAN VISA</p>
        <p>
          Ruko Bali Maxima Blok G-H Jl. GatotSubroto Barat No. 108X Denpasar
          Bali – Indonesia 80116
        </p>
        <p>Phone : +62 361-9093320 ; Email : admin@konsultanvisa.com</p>
      </div>
    </div>
  );
}
