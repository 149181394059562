import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import PayableCreate from "./Create";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import TablePayable from "./Partials/TablePayable";

export { PayableCreate };

export default function Payable() {
  const { t } = useTranslation();
  return (
    <Authenticated header={<Header>{t("Payable")}</Header>}>
      <Head title={t("Payable")} />
      <BeginPage>
        <TablePayable />
      </BeginPage>
    </Authenticated>
  );
}
