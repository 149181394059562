const Degree = {
  S3: 11,
  S2: 10,
  S1: 9,
  D4: 8,
  D3: 7,
  D2: 6,
  D1: 5,
  SMA: 3,
  SMP: 2,
  SD: 1,
  None: -1,
};

const getLabel = (educationLevel) => {
  switch (educationLevel) {
    case Degree.S3:
      return "S3";
    case Degree.S2:
      return "S2";
    case Degree.S1:
      return "S1";
    case Degree.D4:
      return "D4";
    case Degree.D3:
      return "D3";
    case Degree.D2:
      return "D2";
    case Degree.D1:
      return "D1";
    case Degree.SMA:
      return "SMA";
    case Degree.SMP:
      return "SMP";
    case Degree.SD:
      return "SD";
    case Degree.None:
      return "None";
    default:
      return "Unknown";
  }
};

export { getLabel };

export default Degree;
