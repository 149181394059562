const Service = {
  Student: 1,
  Visitor: 2,
  Internship: 3,
  Working_Holiday: 4,
  Courses: 5,
};

const getServiceLabel = (x) => {
  switch (x) {
    case Service.Courses:
      return "Courses";
    case Service.Internship:
      return "Internship";
    case Service.Student:
      return "Student";
    case Service.Visitor:
      return "Visitor";
    case Service.Working_Holiday:
      return "Working_Holiday";
    default:
      return "Unknown";
  }
};

export { getServiceLabel };

export default Service;
