import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "flowbite-react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../../../Components/Search";
import Loader from "../../../Components/Loader";
import { useShortenLink } from "../../../Utils/Context/ShortenLinkContext";
import Delete from "./Delete";

export default function Table() {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const { fetch, shortenLinks, isLoading } = useShortenLink();
  const { t } = useTranslation();
  useEffect(() => {
    fetch({ page: page, keyword: keyword });
  }, [page, keyword]);

  const heads = [t("Name"), t("Link"), t("Clicked"), t("Action")];

  return isLoading ? (
    <div className="w-full flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      <div className="overflow-x-auto">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <Link to={`/shortenlink/create`}>
            <PrimaryButton className="my-5 w-full md:w-fit">
              <p className="w-full text-center">
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {t("Add new shorten link")}
              </p>
            </PrimaryButton>
          </Link>
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {shortenLinks !== null &&
              shortenLinks.map((item, index) => {
                return (
                  <tr key={index} className={"border-b"}>
                    <TableBody>{item.name}</TableBody>
                    <TableBody>
                      <Link
                        className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                        to={`https://konsultanvisa.com/${item.shorten}`}
                      >
                        {`https://konsultanvisa.com/${item.shorten}`}
                      </Link>
                    </TableBody>
                    <TableBody>{item.clicked}</TableBody>
                    <TableBody>
                      <Delete id={item.id} />
                    </TableBody>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
