const HasAppliedVisa = {
  NotYet: 0,
  Have: 1,
};

const getVisaStatusLabel = (visaStatus) => {
  switch (visaStatus) {
    case HasAppliedVisa.NotYet:
      return "NotYet";
    case HasAppliedVisa.Have:
      return "Have";
    default:
      return "Unknown";
  }
};

export { getVisaStatusLabel };

export default HasAppliedVisa;
