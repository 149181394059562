import { useLocation, useNavigate } from "react-router-dom";
import { LogoPng } from "../../Assets";
import Card from "../../Components/Card";
import InputError from "../../Components/InputError";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import Title from "../../Components/Title";
import React, { useEffect, useState } from "react";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import { useSchool } from "../../Utils/Context/SchoolContext";
import { useAuth } from "../../Utils/Context/AuthContext";
import Select2 from "../../Components/Select2";
import KnowFrom, { getKnowFromLabel } from "../../Utils/Enums/KnowFrom";
import HasAppliedVisa, {
  getVisaStatusLabel,
} from "../../Utils/Enums/HasAppliedVisa";
import MarriageStatus, {
  getMarriageStatusLabel,
} from "../../Utils/Enums/MarriageStatus";
import Job, { getJobLabel } from "../../Utils/Enums/Job";
import Degree, { getLabel } from "../../Utils/Enums/Degree";
import { Toaster } from "react-hot-toast";
import Loader from "../../Components/Loader";
import SubTitle from "../../Components/SubTitle";
import StatusCode from "../../Utils/Enums/StatusCode";
import { useClient } from "../../Utils/Context/ClientContext";
import Service, { getServiceLabel } from "../../Utils/Enums/Service";
import DefaultFooter from "../../Sections/DefaultFooter";

export default function CreatClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailParam = queryParams.get("email");
  const clientIdParam = queryParams.get("client_id");

  const isLogin = sessionStorage.getItem("token");

  const { schools, fetchAll: fetchSchool } = useSchool();
  const { fetchByEmail, fetchById, client, update } = useClient();
  const {
    register,
    isSuccess: successRegister,
    errorMessage: errorMessageRegister,
    isLoading: loadingRegister,
  } = useAuth();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(emailParam ?? "");
  const [birthplace, setBirthplace] = useState("");
  const [birthday, setBirthday] = useState("");
  const [marriage_status, setMarriageStatus] = useState("");
  const [address, setAddress] = useState("");
  const [education, setEducation] = useState("");
  const [school_id, setSchool_id] = useState("");
  const [service, setService] = useState("");
  const [graduated_at, setGraduated_at] = useState("");
  const [work, setWork] = useState("");
  const [know_from, setKnow_from] = useState("");
  const [has_applied_visa_before, setHas_applied_visa_before] = useState("");
  const [major, setMajor] = useState("");

  const [successUpdateClient, setSuccessUpdateClient] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (service === Service.Visitor) {
      setToggle(false);
    }
    if (
      service === Service.Courses ||
      service === Service.Internship ||
      service === Service.Student ||
      service === Service.Working_Holiday
    ) {
      setToggle(true);
    }
  }, [service]);

  const attemptRegister = async (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("user[name]", name);
    form.append("user[phone]", phone);
    form.append("user[email]", email);
    form.append("user[marriage_status]", marriage_status);
    form.append("user[birthday]", birthday);
    form.append("user[birthplace]", birthplace);
    form.append("user[password]", `kv${phone}!`);
    form.append("user[address]", address);
    form.append("user[password_confirmation]", `kv${phone}!`);
    form.append("client[work]", work);
    form.append("client[know_from]", know_from);
    form.append("client[has_applied_visa_before]", has_applied_visa_before);
    if (service !== Service.Visitor) {
      form.append("client[education]", education);
      form.append("client[school_id]", school_id);
      form.append("client[graduated_at]", graduated_at);
      if (major !== "") {
        form.append("client[major]", major);
      }
    }
    if (!clientIdParam) {
      form.append("visit[service]", service);
    }

    if (clientIdParam) {
      await update(clientIdParam, form);
      setSuccessUpdateClient(true);
    } else {
      await register(form);
    }
  };

  useEffect(() => {
    async function getData() {
      await fetchById(clientIdParam);
    }
    if (clientIdParam) {
      getData();
    }
  }, [clientIdParam]);

  useEffect(() => {
    if (client) {
      setName(client?.user?.name || "");
      setPhone(client?.user?.phone || "");
      setEmail(client?.user?.email || emailParam || "");
      setBirthplace(client?.user?.birthplace || "");
      setBirthday(client?.user?.birthday || "");
      setMarriageStatus(client?.user?.marriage_status);
      setAddress(client?.user?.address || "");
      setEducation(client?.education || "");
      setSchool_id(client?.school_id || "");
      setGraduated_at(client?.graduated_at || "");
      setWork(client?.work || "");
      setKnow_from(client?.know_from || "");
      setMajor(client?.major || "");
      setHas_applied_visa_before(client?.has_applied_visa_before);
      setService(client?.user?.visits[0]?.service);
    }
  }, [client]);

  useEffect(() => {
    const fetchData = async () => {
      if (successRegister || successUpdateClient) {
        if (isLogin) {
          let thisClient = await fetchByEmail({ email });
          thisClient.data.length > 0 &&
            navigate(`/client/${thisClient.data[0].id}`);
        } else {
          navigate(`/our-services?status=${StatusCode.SUCCESS}`);
        }
      }
    };

    fetchData();
  }, [successRegister, successUpdateClient]);

  return (
    <>
      <div className="py-10 dark:bg-gray-700 bg-slate-100 px-5 md:p-32 bg-[url('/src/Assets/bg-landmark-1.svg')] bg-no-repeat bg-contain">
        <Head title={clientIdParam ? t("Edit") : t("Register new client")} />
        <Toaster />
        <div className="rounded-3xl bg-slate-200 dark:bg-slate-500 p-5 m-5">
          <Title>{clientIdParam ? t("Edit") : t("Register new client")}</Title>
          {emailParam && (
            <SubTitle className={`text-center md:text-left`}>
              {t("Email") +
                ` ${emailParam} ` +
                t("doesn't connect with any account. Please register first.")}
            </SubTitle>
          )}
        </div>
        <form onSubmit={attemptRegister}>
          <Card>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
              <div>
                <InputLabel value={t("Full Name") + "*"} />

                <TextInput
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="mt-1 block w-full"
                  autoComplete="name"
                  isError={errorMessageRegister?.["user.name"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.name"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel value={t("Phone") + "*"} />

                <TextInput
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  className="mt-1 block w-full"
                  autoComplete="phone"
                  isError={errorMessageRegister?.["user.phone"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.phone"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel value={t("Email") + "*"} />

                <TextInput
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="mt-1 block w-full"
                  autoComplete="email"
                  isError={errorMessageRegister?.["user.email"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.email"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel value={t("Birth Place") + "*"} />

                <TextInput
                  onChange={(e) => setBirthplace(e.target.value)}
                  value={birthplace}
                  className="mt-1 block w-full"
                  isError={errorMessageRegister?.["user.birthplace"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.birthplace"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel value={t("Date of birth") + "*"} />

                <TextInput
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  type="date"
                  className="mt-1 block w-full"
                  isError={errorMessageRegister?.["user.birthday"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.birthday"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel value={t("Address") + "*"} />

                <TextInput
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  className="mt-1 block w-full"
                  autoComplete="address"
                  useTextArea
                  isError={errorMessageRegister?.["user.address"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.address"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel
                  value={t("What do you want a consultation for?") + "*"}
                />

                {client &&
                  `${t("choosed")}: ${t(
                    "Service_" + getServiceLabel(service)
                  )}`}

                <Select2
                  options={Object.entries(Service)}
                  onSelected={(e) => setService(e[1])}
                  onTextChanged={() => null}
                  translate={`Service_`}
                />

                <InputError
                  message={errorMessageRegister?.["visit.service"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
            </div>
          </Card>

          {/* school section start */}
          <Card className={toggle ? "" : "hidden"}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
              <div>
                <InputLabel value={t("Last Education") + "*"} />

                {client &&
                  `${t("choosed")}: ${t("Degree_" + getLabel(education))}`}

                <Select2
                  options={Object.entries(Degree)}
                  onSelected={(e) => setEducation(e[1])}
                  onTextChanged={() => null}
                  translate={`Degree_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.education"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel value={t("Major")} />

                <TextInput
                  onChange={(e) => setMajor(e.target.value)}
                  value={major}
                  className="mt-1 block w-full"
                  isError={errorMessageRegister?.["client.major"]}
                />

                <InputError
                  message={errorMessageRegister?.["client.major"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel value={t("School") + "*"} />

                {client && `${t("choosed")}: ${client?.school?.name}`}

                <Select2
                  isSearchable
                  options={schools?.data}
                  onSelected={(e) => setSchool_id(e.id)}
                  onTextChanged={(keyword) => fetchSchool(keyword)}
                />

                <InputError
                  message={errorMessageRegister?.["user.school_id"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel value={t("Graduated at") + "* "} />

                <TextInput
                  type="date"
                  onChange={(e) => setGraduated_at(e.target.value)}
                  value={graduated_at}
                  className="mt-1 block w-full"
                  isError={errorMessageRegister?.["client.graduated_at"]}
                />

                <p className="text-xs italic text-green-400 px-5">
                  {t(
                    "If you are currently an active student, please you provide with the expected date of your graduation! If you have already graduated, kindly provide the date of your graduation."
                  )}
                </p>

                <InputError
                  message={
                    errorMessageRegister?.["client.graduated_at"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>
            </div>
          </Card>
          {/* school section end */}

          <Card>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
              <div>
                <InputLabel value={t("Current Job") + "*"} />

                {client && `${t("choosed")}: ${t("Work_" + getJobLabel(work))}`}

                <Select2
                  options={Object.entries(Job)}
                  onSelected={(e) => setWork(e[1])}
                  onTextChanged={() => null}
                  translate={`Work_`}
                />

                <InputError
                  message={errorMessageRegister?.["client.work"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
              <div className="">
                <InputLabel value={t("Marital Status") + "*"} />

                {client &&
                  `${t("choosed")}: ${t(
                    "MarriageStatus_" +
                      getMarriageStatusLabel(client?.user?.marriage_status)
                  )}`}

                <Select2
                  options={Object.entries(MarriageStatus)}
                  onSelected={(e) => setMarriageStatus(e[1])}
                  onTextChanged={() => null}
                  translate={`MarriageStatus_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.marriage_status"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel
                  value={t("Have You Applied for a Visa Before?") + "*"}
                />

                {client &&
                  `${t("choosed")}: ${t(
                    "HasAppliedVisa_" +
                      getVisaStatusLabel(client?.has_applied_visa_before)
                  )}`}

                <Select2
                  options={Object.entries(HasAppliedVisa)}
                  onSelected={(e) => setHas_applied_visa_before(e[1])}
                  onTextChanged={() => null}
                  translate={`HasAppliedVisa_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.[
                      "client.has_applied_visa_before"
                    ]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel value={t("How Did You Hear About Us?") + "*"} />

                {client &&
                  `${t("choosed")}: ${t(
                    "KnowFrom_" + getKnowFromLabel(know_from)
                  )}`}

                <Select2
                  options={Object.entries(KnowFrom)}
                  onSelected={(e) => setKnow_from(e[1])}
                  onTextChanged={() => null}
                  translate={`KnowFrom_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.know_from"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>
            </div>
          </Card>
          <p className="text-md my-3 italic text-red-400 px-5">
            {"*" + t("Required.")}
          </p>
          <div className="flex justify-center">
            <PrimaryButton disabled={loadingRegister} type="submit">
              {loadingRegister ? <Loader /> : "Submit"}
            </PrimaryButton>
          </div>
          <div className="border-t-slate-500 border-t my-10"></div>
          <div className="flex justify-center">
            <img alt="" src={LogoPng} className="w-full md:w-1/4" />
          </div>
        </form>
      </div>
      <DefaultFooter />
    </>
  );
}
