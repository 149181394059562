import { forwardRef, useEffect, useRef } from "react";

export default forwardRef(function TextInput({
  type = "text",
  className = "",
  isFocused = false,
  useTextArea = false,
  isError = false,
  ...props
}) {
  const display = useTextArea ? (
    <textarea
      rows={5}
      {...props}
      className={
        "border-gray-300 p-3 border-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:ring-primary rounded-3xl shadow-sm " +
        className
      }
    >
      {props.value}
    </textarea>
  ) : (
    <input
      {...props}
      type={type}
      className={
        `${
          isError ? "border-red-300" : "border-gray-300"
        } p-3 border-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:ring-primary rounded-full shadow-sm ` +
        className
      }
    />
  );

  return display;
});
