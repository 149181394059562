import React, { useEffect, useState } from "react";
import Authentication from "../../../Layouts/Authentication";
import Header from "../../../Components/Header";
import Head from "../../../Components/Head";
import BeginPage from "../../../Components/BeginPage";
import { useTranslation } from "react-i18next";
import { useInvoice } from "../../../Utils/Context/InvoiceContext";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import TableBody from "../../../Components/TableBody";
import TableHeader from "../../../Components/TableHeader";
import PrimaryButton from "../../../Components/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCheck,
  faCheckCircle,
  faEye,
  faShare,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import StatusInvoice from "../../../Utils/Enums/StatusInvoice";
import Status from "./Partials/Status";
import toast from "react-hot-toast";
import { getRoles } from "../../../Utils/Auth/Session";
import { FINANCE_ROLE } from "../../../Utils/Tools/RoleRules";

export default function Create() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetchById, invoice, update, isSuccessUpdate, send, isSuccessSend } =
    useInvoice();
  const [grandTotal, setGrandTotal] = useState(0);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchById(id);
  }, [isSuccessSend, isSuccessUpdate]);

  useEffect(() => {
    if (invoice) {
      let total = 0;
      invoice.invoice_clients.forEach((ic) => {
        ic.invoice_details.forEach((id) => {
          total += id.rate * id.unit_price * id.qty;
        });
      });
      setGrandTotal(total);
    }
  }, [invoice]);

  const updateStatus = async (invoice_id, status) => {
    await update(invoice_id, { status: status });
  };
  const sendInvoice = async (invoice_id) => {
    await send(invoice_id);
  };

  useEffect(() => {
    function reload() {
      isSuccessUpdate && toast.success(t("Invoice Status has been updated"));
      isSuccessSend && toast.success(t("Invoice has been sent by email"));
      isSuccessUpdate && navigate(-1);
    }
    isSuccessUpdate && reload();
    isSuccessSend && reload();
    getRoles().map((role) => {
      setRoles(role.name);
    });
  }, [isSuccessUpdate, isSuccessSend]);

  let rowNumber = 0;

  return (
    <Authentication header={<Header>{t("Invoice")}</Header>}>
      <Head title={t("Invoice")} />
      <BeginPage>
        <div className="p-10">
          <div className="flex justify-center">
            <p className="text-3xl font-bold text-slate-900 underline">
              {invoice?.number}
            </p>
          </div>
          <div className="mt-10">
            <div className="text-xl">
              <p>
                Invoice to:{" "}
                {invoice?.invoice_clients?.map((client, index) => (
                  <p
                    className="font-bold"
                    key={index}
                  >{`${client.client_name}`}</p>
                ))}
              </p>
            </div>
            <div className="mt-3">
              <p>
                Invoice Date:{" "}
                <span className="font-bold">
                  {moment(invoice?.created_at).format("LLL")}
                </span>
              </p>
            </div>
            <div>
              <p>
                Due Date:{" "}
                <span className="font-bold">
                  {moment(invoice?.due).format("LL")}
                </span>
              </p>
            </div>
            {invoice?.is_sent ? (
              <span className="font-bold text-slate-500">
                {t("Invoice has been sent by email")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div className=" border-t mt-5 grid md:flex grid-cols-1 md:grid-cols-2 justify-between md:gap-5">
            <div className="flex justify-start items-center  w-full">
              <Status invoice={invoice} />
            </div>
            <div className="w-full grid md:flex grid-cols-1 md:grid-cols-2 justify-end md:gap-5">
              {invoice !== null && invoice?.status === StatusInvoice.UNPAID && (
                <>
                  <PrimaryButton
                    onClick={() =>
                      window.open(
                        `https://api.konsultanvisaid.com/api/invoice/view/${invoice?.id}`,
                        "_blank"
                      )
                    }
                    className="my-5 w-full md:w-fit"
                  >
                    <p className="w-full text-center">
                      <FontAwesomeIcon className="mr-3" icon={faEye} />
                      {t("Preview")}
                      <FontAwesomeIcon
                        className="ml-1"
                        icon={faArrowUpRightFromSquare}
                      />
                    </p>
                  </PrimaryButton>
                  {invoice?.status === StatusInvoice.PAID ? (
                    <></>
                  ) : invoice?.is_sent ? (
                    <PrimaryButton
                      onClick={() =>
                        updateStatus(invoice?.id, StatusInvoice.PAID)
                      }
                      className="my-5 w-full md:w-fit"
                    >
                      <p className="w-full text-center">
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faCheckCircle}
                        />
                        {t("Mark as Paid")}
                      </p>
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      onClick={() => sendInvoice(invoice?.id)}
                      className="my-5 w-full md:w-fit"
                    >
                      <p className="w-full text-center">
                        <FontAwesomeIcon className="mr-3" icon={faShare} />
                        {t("Send")}
                      </p>
                    </PrimaryButton>
                  )}
                </>
              )}

              {invoice !== null &&
                invoice?.status === StatusInvoice.UNPAID &&
                FINANCE_ROLE.includes(roles) && (
                  <>
                    <PrimaryButton
                      onClick={() =>
                        updateStatus(invoice?.id, StatusInvoice.VOID)
                      }
                      className="my-5 w-full md:w-fit"
                    >
                      <p className="w-full text-center">
                        <FontAwesomeIcon className="mr-3" icon={faX} />
                        {t("Mark as Void")}
                      </p>
                    </PrimaryButton>
                    {/* <PrimaryButton
                      onClick={() =>
                        updateStatus(invoice?.id, StatusInvoice.UNPAID)
                      }
                      className="my-5 w-full md:w-fit"
                    >
                      <p className="w-full text-center">
                        <FontAwesomeIcon className="mr-3" icon={faCheck} />
                        {t("Mark as Confirm")}
                      </p>
                    </PrimaryButton> */}
                  </>
                )}
            </div>
          </div>
          <div className="mt-10 p-2 rounded-2xl">
            <table className="w-full mb-10">
              <thead>
                <tr className="text-center font-bold text-xl">
                  <TableHeader title={`No`} />
                  <TableHeader title={`Description`} />
                  <TableHeader title={`Quantity`} />
                  <TableHeader title={`Amount`} />
                  <TableHeader title={`Total in IDR`} />
                </tr>
              </thead>
              {invoice !== null &&
                invoice.invoice_clients.map((ic, i) => (
                  <tbody key={i}>
                    {ic.invoice_details.map((id, ix) => (
                      <tr key={ix}>
                        <>
                          <TableBody>{++rowNumber}</TableBody>
                          <TableBody>
                            <p>{`${id.item_name} (${ic.client_name})`}</p>
                            {id.note && (
                              <p className="p-2 dark:bg-slate-800 rounded-r-md border-l-4 italic text-slate-400">
                                {id.note}
                              </p>
                            )}
                          </TableBody>
                          <TableBody className={`text-center`}>
                            {id.qty.toLocaleString()}
                          </TableBody>
                          <TableBody className={`text-end`}>{`IDR ${(
                            id.rate * id.unit_price
                          ).toLocaleString()} ${
                            id.currency === "IDR"
                              ? ""
                              : `(${id.currency} ${(
                                  id.unit_price * 1
                                ).toLocaleString()})`
                          }`}</TableBody>
                          <TableBody className={`text-end`}>{`IDR ${(
                            id.rate *
                            id.unit_price *
                            id.qty
                          ).toLocaleString()} ${
                            id.currency === "IDR"
                              ? ""
                              : `(${id.currency} ${(
                                  id.unit_price * 1
                                ).toLocaleString()})`
                          }`}</TableBody>
                        </>
                      </tr>
                    ))}
                  </tbody>
                ))}
              <tbody>
                <tr>
                  <TableBody></TableBody>
                  <TableBody></TableBody>
                  <TableBody></TableBody>
                  <TableBody className={`col-span-4 text-end font-bold`}>
                    {t("GRAND TOTAL in IDR")}
                  </TableBody>
                  <TableBody className={`text-end font-bold text-xl`}>
                    IDR {grandTotal.toLocaleString()}
                  </TableBody>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          {invoice !== null &&
            invoice.invoice_clients.map((ic) =>
              ic.invoice_details
                .reduce((acc, current) => {
                  let mergedDetails = acc.find(
                    (item) =>
                      item.currency === current.currency &&
                      item.rate === current.rate
                  );
                  if (!mergedDetails) {
                    acc.push(current);
                  }
                  return acc;
                }, [])
                .map((mergedDetail, ix) => {
                  if (mergedDetail.currency === "IDR") {
                    return "";
                  } else {
                    return (
                      <p key={ix}>
                        1 {mergedDetail.currency} ={" "}
                        {(mergedDetail.rate * 1).toLocaleString()} IDR
                      </p>
                    );
                  }
                })
            )}
        </div>
      </BeginPage>
    </Authentication>
  );
}
