import { faArrowsRotate, faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputLabel from "../../../../Components/InputLabel";
import TableHeader from "../../../../Components/TableHeader";
import TableBody from "../../../../Components/TableBody";
import PrimaryButton from "../../../../Components/PrimaryButton";
import UnderlineInput from "../../../../Components/UnderlineInput";
import Select2 from "../../../../Components/Select2";
import { useEffect, useState } from "react";
import { useClient } from "../../../../Utils/Context/ClientContext";
import { usePayable } from "../../../../Utils/Context/PayableContext";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import toast from "react-hot-toast";

export const ClientSection = ({
  currentIndex,
  details,
  onItemAdded,
  onClientRemoved,
  onItemDeleted,
  onClientSelected,
}) => {
  const { fetchAll: fetchAllClient, clients: clientsList } = useClient();
  const {
    fetchReletedPayableByClientId: fetchReletedPayable,
    payables: payablesList,
  } = usePayable();

  const { t } = useTranslation();
  const [payableSelected, setPayableSelected] = useState(null);
  const [qty, setQty] = useState(1);
  const [note, setNote] = useState("");
  const [item_name, setItem_name] = useState("");
  const [unit_price, setUnit_price] = useState(0);
  const [currency, setCurrency] = useState("");
  const [rate, setRate] = useState(0);
  const [isInputText, setIsInputText] = useState(false);

  useEffect(() => {
    fetchAllClient({ page: 1, keyword: null });
  }, []);

  const getReletedPayable = (clientSelected) => {
    fetchReletedPayable({ client_id: clientSelected });
  };

  const setData = () => {
    if (!isInputText && payableSelected.id === undefined) {
      toast.error(t("Please select the item"));
    } else if (isInputText && item_name === "") {
      toast.error(t("Please type the item name"));
    } else {
      let data = {
        payable: payableSelected,
        qty,
        unit_price,
        total: unit_price * qty,
        totalIDR: unit_price * rate * qty,
        ...(item_name !== ""
          ? { item_name: item_name }
          : { payable_id: payableSelected.id }),
        ...(note !== "" && { note }),
        ...(currency !== "" && { currency }),
        ...(rate !== "" && { rate }),
      };
      onItemAdded(data);

      setQty(1);
      setNote("");
      setCurrency("");
      setItem_name("");
      setRate(0);
      setUnit_price(0);
      setPayableSelected(null);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-5 mt-5 border p-5 rounded-3xl">
      <div className="w-full flex justify-end">
        <button
          className="hover:bg-red-600 bg-red-400 transition-all duration-500 rounded-md px-3 py-1"
          onClick={() => onClientRemoved(currentIndex)}
        >
          <FontAwesomeIcon icon={faX} />
        </button>
      </div>
      <div>
        <InputLabel>Client</InputLabel>
        <Select2
          isSearchable
          options={clientsList?.data}
          onSelected={(client) => {
            if (client.id !== undefined) {
              getReletedPayable(client.id);
              onClientSelected(client.id);
            }
          }}
          onTextChanged={(keyword) =>
            fetchAllClient({ page: 1, keyword: keyword })
          }
        />
      </div>
      {payablesList && (
        <div className="overflow-x-auto">
          <table className="w-full mb-10">
            <thead>
              <tr className="text-center font-bold text-xl">
                <TableHeader title={`Item`} />
                <TableHeader title={`Price`} />
                <TableHeader title={`Qty`} />
                <TableHeader title={`Total`} />
                <TableHeader title={`Total in IDR`} />
                <TableHeader title={`Action`} />
              </tr>
            </thead>
            <tbody>
              {details.map((item, index) => (
                <tr key={index} className={"border-b"}>
                  <TableBody>
                    <p>{`${
                      item.item_name ? item.item_name : item.payable?.name
                    }`}</p>
                    <p className="border-l-4 bg-slate-800 rounded-r-md pl-2 italic text-slate-400 ">
                      {item.note}
                    </p>
                  </TableBody>
                  <TableBody
                    className={`text-xl justify-center items-center text-end`}
                  >
                    {`${item.currency || ""} ${
                      (item.unit_price * 1).toLocaleString() || ""
                    }`}
                  </TableBody>
                  <TableBody className={`text-center`}>
                    {item.qty.toLocaleString()}
                  </TableBody>
                  <TableBody
                    className={`text-xl font-bold justify-center items-center text-end`}
                  >
                    {`${item.currency || ""} ${
                      item.total.toLocaleString() || ""
                    }`}
                  </TableBody>
                  <TableBody>
                    <p
                      className={`text-xl font-bold justify-center items-center text-end`}
                    >{`IDR ${item.totalIDR.toLocaleString() || ""}`}</p>
                    <p className="text-end border-r-4 italic pr-2 text-slate-400 text-xs">
                      {`${item.currency || ""} 1 = IDR ${
                        (item.rate * 1).toLocaleString() || ""
                      }`}
                    </p>
                  </TableBody>
                  <TableBody className={`flex justify-center items-center`}>
                    <div
                      className="bg-red-400 dark:bg-red-400 rounded-full w-8 h-8 hover:bg-red-500 dark:hover:bg-red-500 flex justify-center items-center"
                      onClick={() => onItemDeleted(index)}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </div>
                  </TableBody>
                </tr>
              ))}
              {details.length > 0 && (
                <tr className="font-bold bg-slate-500 dark:bg-slate-900 text-center text-xl">
                  <td colSpan="4" className="text-end">
                    Total
                  </td>
                  <td className="text-end text-green-500 lg:text-3xl">{`IDR ${(
                    details.reduce(
                      (total, item) => total + item.totalIDR * 1,
                      0
                    ) || 0
                  ).toLocaleString()}`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {payablesList && (
        <div className="flex flex-col items-center justify-center">
          <div className="w-1/2 font-bold bg-slate-500 rounded-t-3xl h-10 flex justify-center items-center text-xs lg:text-xl">
            {t("ADD NEW ITEM")}
          </div>
          <div
            className={"grid grid-cols-1 gap-3 bg-slate-500 p-3 rounded-3xl"}
          >
            <div className="lg:col-span-3 flex justify-center items-center gap-1">
              <Tooltip
                content={
                  isInputText ? t("Change to list") : t("Change to input text")
                }
              >
                <div
                  onClick={() => setIsInputText(!isInputText)}
                  className={`hover:bg-slate-400 p-1 w-6 h-6 rounded-full flex justify-center items-center cursor-pointer transition-all duration-500 ${
                    isInputText ? "rotate-180" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
              </Tooltip>
              {isInputText ? (
                <div className="w-full">
                  <UnderlineInput
                    placeholder={t("Name of item")}
                    value={item_name}
                    onChange={(e) => setItem_name(e.target.value)}
                  />
                </div>
              ) : (
                <div className="w-full">
                  <Select2
                    reset={!payableSelected && true}
                    options={payablesList?.data}
                    onSelected={(e) => {
                      setPayableSelected(e);
                      setUnit_price(e.price);
                      setCurrency(e.currency);
                      setRate(e.default_rate);
                    }}
                    onTextChanged={() => null}
                  />
                </div>
              )}
            </div>

            {/* attribute input */}
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
              <div className="w-full">
                <UnderlineInput
                  placeholder={`Quantity`}
                  value={qty}
                  className="text-center"
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>
              <div className="w-full">
                <UnderlineInput
                  placeholder={`Price per unit`}
                  value={unit_price}
                  className="text-center"
                  onChange={(e) => setUnit_price(e.target.value)}
                />
              </div>
              <div className="w-full">
                <UnderlineInput
                  placeholder={`Currency`}
                  value={currency}
                  className="text-center"
                  onChange={(e) => setCurrency(e.target.value.toUpperCase())}
                />
              </div>
              <div className="w-full">
                <UnderlineInput
                  placeholder={`Rate in IDR`}
                  value={rate}
                  className="text-center"
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
              <div className={`w-full`}>
                <UnderlineInput
                  placeholder={`Note`}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <div className={`justify-center hidden lg:flex`}>
              <PrimaryButton
                className="rounded-full bg-green-400 dark:bg-green-400 hover:bg-green-500 dark:hover:bg-green-500 h-12 w-12 flex items-center justify-center"
                onClick={() => setData()}
              >
                <FontAwesomeIcon className="text-white" icon={faPlus} />
              </PrimaryButton>
            </div>
          </div>
          <div className={`justify-center flex lg:hidden`}>
            <PrimaryButton
              className="bg-green-400 dark:bg-green-400 hover:bg-green-500 dark:hover:bg-green-500 w-full"
              onClick={() => setData()}
            >
              <p className="text-center w-full">
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                Add
              </p>
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientSection;
