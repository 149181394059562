import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import { useTranslation } from "react-i18next";
import Head from "../../Components/Head";
import BeginPage from "../../Components/BeginPage";
import Card from "../../Components/Card";
import { Avatar } from "flowbite-react";
import Title from "../../Components/Title";
import SubTitle from "../../Components/SubTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../Utils/Context/UserContext";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import Select from "../../Components/Select";
import { useRole } from "../../Utils/Context/RoleContext";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";

export default function Show() {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const { user, fetchById: getUserById, isLoading: loadingUser } = useUser();
  const {
    roles,
    fetchAll: getRoles,
    assign,
    revoke,
    isLoading: loadingRole,
  } = useRole();
  const { id } = useParams();

  useEffect(() => {
    getUserById(id);
    getRoles();
  }, [reload]);

  return (
    <Authentication header={<Header>{!loadingUser && user.name}</Header>}>
      <Head title={!loadingUser && user.name} />
      <BeginPage className={`grid grid-cols-1 md:grid-cols-3`}>
        <Card className={`grid grid-cols-1 md:grid-cols-2 col-span-2`}>
          <div className="w-full">
            <Avatar
              size={`xl`}
              alt="profile"
              rounded
              img={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`}
            />
          </div>
          <div>
            <div className="flex flex-col gap-3">
              <div className="overflow-hidden">
                <InputLabel>{t("Name")}</InputLabel>
                <SubTitle className="whitespace-nowrap overflow-ellipsis text-sm">
                  {loadingUser ? <Loader /> : user.name}
                </SubTitle>
              </div>
              <div className="overflow-hidden">
                <InputLabel>{t("Email")}</InputLabel>
                <SubTitle className="whitespace-nowrap overflow-ellipsis text-sm">
                  {loadingUser ? <Loader /> : user.email}
                </SubTitle>
              </div>
              <div className="overflow-hidden">
                <InputLabel>{t("Role")}</InputLabel>
                <SubTitle className="whitespace-nowrap overflow-ellipsis text-sm">
                  <div className="flex gap-1 justify-left">
                    {loadingUser ? (
                      <Loader />
                    ) : (
                      user?.roles.map((item, index) => (
                        <p
                          key={index}
                          className="bg-red-300 p-1 rounded-full text-white text-sm"
                        >
                          {item.name}
                        </p>
                      ))
                    )}
                  </div>
                </SubTitle>
              </div>

              <div className="w-full flex justify-center">
                <Link to={`/user/edit/${user?.id}`}>
                  <PrimaryButton>
                    <FontAwesomeIcon icon={faPencil} className="mr-3" />
                    Edit
                  </PrimaryButton>
                </Link>
              </div>
            </div>
          </div>
        </Card>
        <div className="grid">
          <Card className={`flex flex-col flex-1`}>
            <div className="w-full">
              <Title size={`sm`}>{t("Access role:")}</Title>
            </div>
            <div>
              <Select
                onChange={async (e) => {
                  const roleId = JSON.parse(e.target.value).id;
                  const isUserHasRole = JSON.parse(
                    e.target.value
                  ).isUserHasRole;
                  if (isUserHasRole) {
                    await revoke(id, roleId).then(() => setReload(!reload));
                  } else {
                    await assign(id, roleId).then(() => setReload(!reload));
                  }
                  e.target.value = t("Select action");
                }}
                disabled={loadingRole}
              >
                <Select.Option disabled>{t("Select action")}</Select.Option>
                {roles?.data?.map((item, index) => {
                  const isUserHasRole = user?.roles.some(
                    (userRole) => userRole.id === item.id
                  );
                  return (
                    <Select.Option
                      key={index}
                      value={JSON.stringify({
                        id: item.id,
                        isUserHasRole: isUserHasRole,
                      })}
                    >
                      {isUserHasRole
                        ? `Revoke from ${item.name}`
                        : `Assign to ${item.name}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </Card>
        </div>
      </BeginPage>
    </Authentication>
  );
}
