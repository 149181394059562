import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import ProgramCreate from "./Create";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import TableProgram from "./Partials/TableProgram";

export { ProgramCreate };

export default function Program() {
  const { t } = useTranslation();
  return (
    <Authenticated header={<Header>{t("Programs")}</Header>}>
      <Head title={t("Programs")} />
      <BeginPage>
        <TableProgram />
      </BeginPage>
    </Authenticated>
  );
}
